import React, { PureComponent } from "react";
import { Route, Switch, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/auth";

import {
  Login,
  Dashboard,
  SignUp,
  Components,
  MyInvoices,
  MyServices,
  Contact,
  AccountDetails,
  Payments,
  RouteNotFound,
  AddNewPaymentMethod,
  TermsAndConditions,
  PrivacyPolicy,
  EditAccountDetails,
  PaymentConfirmation,
  FivehundredError,
  LogOut,
  GetRepairEstimate,
  ChangeService,
  RepairRequest,
  CaseResponse,
} from "./index";

import { Navbar } from "react-bootstrap";

import {
  onASNameChange,
  onASClearFields,
} from "../reducers/AccountSetupReducer";
import "../css/style.scss";
import LoginImage from "./Screens/LoginImage.js";

class Root extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      account: false,
    };
  }

  componentDidMount() {
    let config = {
      apiKey: "AIzaSyCxFTT6FdezPbSybsd0ElOBItvENEwgwls",
      authDomain: "liv-test-4f9fa.firebaseapp.com",
      databaseURL: "https://liv-test-4f9fa.firebaseio.com",
      projectId: "liv-test-4f9fa",
      storageBucket: "liv-test-4f9fa.appspot.com",
      messagingSenderId: "206834849048",
    };

    firebase.initializeApp(config);

    const contactId = localStorage.getItem("contactId");
    const accountId = localStorage.getItem("accountId");
    const region = localStorage.getItem("region");
    if (contactId !== "undefined") {
      this.props.onASNameChange(null, accountId, contactId, region);
    }
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        {/* {!this.props.isLoggedIn ? (
          <div style={{ height: "100%" }}>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/loginRedirect" component={LoginRedirect} />
              <Route exact path="/loginCallback" component={LoginCallback} />
              <Route path="*" component={RouteNotFound} />
            </Switch>
          </div>
        ) : ( */}
        <div style={{ height: "100%" }}>
          <div
            onClick={() => {
              if (this.state.account) {
                let account = !this.state.account;
                this.setState({ account });
              }
            }}
            style={{ height: "100%" }}
          >
            {/* {!this.props.isLoggedIn ? (
                <Switch>
                  <Route exact path="/" component={Login} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/sign-up">
                    {!this.props.signedUp ? (
                      (localStorage.removeItem("signedUp"), (<SignUp />))
                    ) : (
                      <div>
                        Please check your inbox and verify your email. Then{" "}
                        <Link to="/login">Login</Link>
                      </div>
                    )}
                  </Route>
                  <Route path="*" component={RouteNotFound} />
                  <Route
                    exact
                    path="/"
                    component={() => (
                      <div>
                        logged out not found
                        <Link to="/login"> To login screen </Link>
                      </div>
                    )}
                  />
                  <Route exact path="/components" component={Components} />
                </Switch>
              ) : ( */}
            <div style={{ height: "100%" }}>
              <Navbar />
              <Switch>
                {/* <Route exact path="/" component={Dashboard} /> */}
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/sign-up">
                  {!this.props.signedUp ? (
                    (localStorage.removeItem("signedUp"), (<SignUp />))
                  ) : (
                    <div>
                      Please check your inbox and verify your email. Then{" "}
                      <Link to="/login">Login</Link>
                    </div>
                  )}
                </Route>
                <Route exact path="/Dashboard" component={Dashboard} />
                <Route exact path="/MyInvoices" component={MyInvoices} />
                <Route exact path="/MyServices" component={MyServices} />
                <Route exact path="/Contact" component={Contact} />
                <Route exact path="/Payments" component={Payments} />
                <Route exact path="/CaseResponse" component={CaseResponse} />
                <Route
                  exact
                  path="/TermsAndConditions"
                  component={TermsAndConditions}
                />
                <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
                <Route
                  exact
                  path="/AddNewPaymentMethod"
                  component={AddNewPaymentMethod}
                />
                <Route
                  exact
                  path="/AccountDetails"
                  component={AccountDetails}
                />
                <Route
                  exact
                  path="/AccountDetails/:value"
                  component={AccountDetails}
                />
                <Route exact path="/LoginImage" component={LoginImage} />
                <Route
                  exact
                  path="/EditAccountDetails"
                  component={EditAccountDetails}
                />
                <Route
                  exact
                  path="/PaymentConfirmation"
                  component={PaymentConfirmation}
                />
                <Route
                  exact
                  path="/FivehundredError"
                  component={FivehundredError}
                />
                <Route exact path="/logout" component={LogOut} />
                <Route
                  exact
                  path="/GetRepairEstimate"
                  component={GetRepairEstimate}
                />
                <Route exact path="/ChangeService" component={ChangeService} />
                <Route exact path="/RepairRequest" component={RepairRequest} />
                <Route exact path="/components" component={Components} />
                <Route path="*" component={RouteNotFound} />
              </Switch>
            </div>
            {/* )} */}
          </div>
        </div>
        {/* )} */}

        {/* Add footer here */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: !!state.account.contactId,
  signedUp: !!state.account.displayName,
  email: state.auth.email,
});

export default withRouter(
  connect(mapStateToProps, { onASNameChange, onASClearFields })(Root)
);
