/** @format */

import React, { PureComponent } from "react";
import "../../css/style.scss";
import Nav from "react-bootstrap/Nav";

class ServicesDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: {},
      showChangePassword: false,
      changePasswordSuccess: false,
    };
  }
  handleMouseLeaveFunc = () => {
    this.props.handleMouseLeave();
  };

  render() {
    return (
      <div onMouseLeave={() => this.handleMouseLeaveFunc()}>
        <Nav className="servicesDropdownContainer">
          <Nav.Item
            style={{ borderBottomWidth: "1px", borderBottomColor: "white" }}
          >
            <Nav.Link
              className="navDropdownItem"
              href="/MyServices"
              eventKey="ViewMyServiceVisits"
              style={{}}
              tabIndex={13}
              aria-label="Navigate to my services"
            >
              VIEW MY SERVICE VISITS
            </Nav.Link>
          </Nav.Item>
          <span className="accountDropdownSpacer" />
          <Nav.Item>
            <Nav.Link
              className="navDropdownItem"
              eventKey="RepairRequest"
              href="/RepairRequest"
              tabIndex={15}
              aria-label="Navigate to get repair estimate"
            >
              GET A REPAIR ESTIMATE
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    );
  }
}

export default ServicesDropdown;
