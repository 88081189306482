import React, { PureComponent } from "react";
import { MdOutlineArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import "../../css/style.scss";
import dialogueIcon from "../../assets/svg-icos/ico-dialogue-yellow.svg";

class DashboardMessage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="appDashboardMessageContainer">
        <img
          src={dialogueIcon}
          alt="Dashboard message"
          style={{ height: "100%" }}
        />
        <div className="appDashboardMessage">
          <p
            style={{
              color: "white",
              fontSize: "20px",
              fontFamily: "museo-sans",
              fontWeight: "700",
              maxWidth: "315px",
              marginBottom: "10px",
            }}
          >
            Get important notifications about your account balance via SMS, add
            a mobile number now
          </p>
          <Link
            className="goldUnderlineHover"
            to={`/AccountDetails/${"Notifications"}`}
            style={{ textDecoration: "none" }}
            tabIndex={23}
            aria-label="Navigate to SMS details"
          >
            <p
              style={{
                color: "#FFE160",
                textAlign: "left",
                marginBottom: "0px",
                fontFamily: "museo-sans",
                fontWeight: "100",
                fontSize: "16px",
              }}
            >
              Add SMS
              <MdOutlineArrowRight size={20} style={{ marginLeft: "-5px" }} />
            </p>
          </Link>
        </div>
      </div>
    );
  }
}

export default DashboardMessage;
