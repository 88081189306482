import React, { PureComponent } from "react";
import {
  GLOBAL_DOMAIN,
  GLOBAL_SF_DOMAIN,
  GLOBAL_WEB_DOMAIN,
} from "../../actions/types";
import axios from "axios";

import { connect } from "react-redux";
import AppButton from "../CustomComponents/AppButton";
import AppNavbarWave from "../CustomComponents/AppNavbarWave";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import phoneIcon from "../../assets/svg-icos/ico-phone.svg";
import LoadErrorModal from "../CustomComponents/LoadErrorModal";
import { Dropdown, Modal } from "react-bootstrap";
import { BsChevronRight } from "react-icons/bs";
import AppGreyButton from "../CustomComponents/AppGreyButton";
import Spinner from "react-bootstrap/Spinner";
import TermsAndPrivacy from "../CustomComponents/TermsAndPrivacy";
import PrivacyPolicy from "../CustomComponents/PrivacyPolicy";

const CustomMethodToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    href="Preferred Method of Contact"
    className="contactDropdownToggle"
    style={{
      alignItems: "center",
      color: "black",
      textDecoration: "none",
      fontSize: 16,
    }}
    tabIndex={21}
    aria-label="Contact method toggle"
  >
    <p style={{ marginBottom: "0px", paddingLeft: "1rem" }}>{children}</p>
    <BsChevronRight size={20} style={{ marginRight: "1rem" }} />
  </a>
));
const CustomTopicToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    href="Select a Topic"
    className="contactDropdownToggle"
    style={{
      alignItems: "center",
      color: "black",
      textDecoration: "none",
      fontSize: 16,
    }}
    tabIndex={24}
    aria-label="Contact topic toggle"
  >
    <p style={{ marginBottom: "0px", paddingLeft: "1rem" }}>{children}</p>
    <BsChevronRight size={20} style={{ marginRight: "1rem" }} />
  </a>
));

class Contact extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      spinner: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      preferredMethod: "",
      selectedTopic: "",
      commentsQuestions: "",
      showLoadErrorModal: false,
      showSubmissionResponseModal: false,
      showSubmissionError: false,
      fileArray: [],
    };
  }
  componentDidMount = async () => {
    let accountID = localStorage.getItem("accountId");
    let contactID = localStorage.getItem("contactId");
    if (!accountID && !contactID) {
      window.location.href = `${GLOBAL_SF_DOMAIN}/services/oauth2/authorize?client_id=3MVG9hq7jmfCuKfcfEvXMNxD433CfsCPKAYx8ArK6TiGKw1aiwZ6qkalCe7mrAraIjbIxajp2kecEnIMR1U40&redirect_uri=https://${GLOBAL_WEB_DOMAIN}/login&scope=refresh_token%20api%20web&response_type=code&prompt=login`;
    } else {
      try {
        let getContact = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/services/contact`,
          {
            params: {
              Id: localStorage.getItem("contactId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );
        this.setState({});
        let getAccount = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/services/account`,
          {
            params: {
              Id: localStorage.getItem("accountId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );
        let data = getContact?.data?.records;
        let accountData = getAccount?.data?.records;
        if (data && accountData) {
          this.setState({
            addressOne: accountData[0]?.BillingAddress?.street,
            city: accountData[0]?.BillingAddress?.city,
            state: accountData[0]?.BillingAddress.stateCode,
            zip: accountData[0]?.BillingAddress.postalCode,
            email: data[0]?.Email,
            phone: data[0]?.Phone,
            firstName: data[0]?.FirstName,
            lastName: data[0]?.LastName,
          });
        } else {
          this.setState({
            showLoadErrorModal: true,
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  onSubmit = async () => {
    this.setState({ spinner: true });
    let obj = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      preferredMethod: this.state.preferredMethod,
      selectedTopic: this.state.selectedTopic,
      commentsQuestions: this.state.commentsQuestions,
      Id: localStorage.getItem("contactId"),
    };
    let createCase = await axios.get(
      `${GLOBAL_DOMAIN}/api/new/services/createCase`,
      {
        params: {
          json: obj,
          token: localStorage.getItem("token"),
          baseDomain: window.location.href,
        },
      }
    );
    if (createCase.data.success) {
      // console.log(createCase.data.id);
      // console.log(this.state.fileArray);
      if (this.state.fileArray.length) {
        for (let i = 0; i < this.state.fileArray.length; i++) {
          let filesObj = {
            id: createCase.data.id,
            fileArray: this.state.fileArray[i],
            baseDomain: window.location.href,
          };
          try {
            await axios.post(
              `${GLOBAL_DOMAIN}/api/new/services/submitFileToCase`,
              filesObj,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );

            // console.log("submit", submitFileToCase.data);
          } catch (error) {
            console.error("Error submitting file:", error);
          }
        }
      }
      this.props.history.push({
        pathname: "/CaseResponse",
        state: {
          success: true,
        },
      });
      this.setState({
        showSubmissionResponseModal: true,
        showSubmissionError: false,
        spinner: false,
      });
    } else {
      this.props.history.push({
        pathname: "/CaseResponse",
        state: {
          success: false,
        },
      });
      this.setState({
        showSubmissionResponseModal: false,
        showSubmissionError: true,
        spinner: false,
      });
    }
  };

  handleSelectMethod = (method) => {
    this.setState({ preferredMethod: method });
  };
  handleSelectTopic = (topic) => {
    this.setState({ selectedTopic: topic });
  };

  handleFileInputChange = async (event) => {
    const files = event.target.files; // Get all selected files
    // console.log(event.target.files);
    const imageDataArray = []; // Array to store base64-encoded image data

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          let base64Data = reader.result;
          base64Data = base64Data.split("base64,"); // Get the base64-encoded image data
          imageDataArray.push(base64Data[1]); // Push the image data to the array
          // If all files have been processed, update state with the array of image data
          if (imageDataArray.length === files.length) {
            // console.log(imageDataArray);
            this.setState({ fileArray: imageDataArray });
          }
        };
        reader.readAsDataURL(file); // Read the file as a data URL
        reader.onerror = (error) => {
          console.error("Error reading file:", error);
        };
      }
    }
  };

  render() {
    return (
      <>
        <AppNavbarWave />
        {this.state.spinner ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p
              style={{
                fontFamily: "museo-sans",
                marginTop: 10,
                textAlign: "center",
                marginLeft: "5%",
                marginRight: "5%",
                maxWidth: "90%",
              }}
            >
              Currently processing, this can take up to a minute—please do not
              close this window or exit your browser until you get a
              confirmation.
            </p>
          </div>
        ) : (
          <>
            <div className="contactFlexContainer">
              <div className="contactContentContainer">
                <h1
                  className="myAccountHeader"
                  style={{
                    fontSize: "42px",
                    color: "#1A4EA2",
                    fontFamily: "Oswald",
                    fontWeight: "300",
                  }}
                >
                  Contact Us
                </h1>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "300",
                  }}
                >
                  Please fill in the form below if you need our help for any
                  reason, whether it's a question with weekly service or
                  equipment that has stopped working. We're here for you!
                </p>
                <p className="contactSubHeader" style={{ marginTop: 50 }}>
                  CONTACT SUPPORT
                </p>

                <div className="d-lg-flex">
                  <Form.Group
                    className="form_item"
                    style={{ marginRight: "20px" }}
                  >
                    <FloatingLabel
                      controlId="floatingFirstNameInput"
                      label="First Name*"
                      className="mb-3"
                    >
                      <Form.Control
                        placeholder="First Name"
                        value={this.state.firstName}
                        onChange={(text) => {
                          this.setState({
                            firstName: text.target.value,
                          });
                        }}
                        style={{
                          width: "100%",
                          height: "60px",
                          border: "1px solid #D5D5D5",
                          borderRadius: "5px",
                        }}
                        tabIndex={17}
                        aria-label="First name input"
                      ></Form.Control>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="form_item">
                    <FloatingLabel
                      controlId="floatingLastNameInput"
                      label="Last Name*"
                      className="mb-3"
                    >
                      <Form.Control
                        placeholder="Last Name"
                        value={this.state.lastName}
                        onChange={(text) => {
                          this.setState({
                            lastName: text.target.value,
                          });
                        }}
                        style={{
                          width: "100%",
                          height: "60px",
                          border: "1px solid #D5D5D5",
                          borderRadius: "5px",
                        }}
                        tabIndex={18}
                        aria-label="Last name input"
                      ></Form.Control>
                    </FloatingLabel>
                  </Form.Group>
                </div>
                <div className="d-lg-flex">
                  <Form.Group
                    className="form_item"
                    style={{ marginRight: "20px" }}
                  >
                    <FloatingLabel
                      controlId="floatingEmailInput"
                      label="Email*"
                      className="mb-3"
                    >
                      <Form.Control
                        placeholder="Email"
                        value={this.state.email}
                        onChange={(text) => {
                          this.setState({
                            email: text.target.value,
                          });
                        }}
                        style={{
                          width: "100%",
                          height: "60px",
                          border: "1px solid #D5D5D5",
                          borderRadius: "5px",
                        }}
                        tabIndex={19}
                        aria-label="Email input"
                      ></Form.Control>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="form_item">
                    <FloatingLabel
                      controlId="floatingPhoneInput"
                      label="Phone*"
                      className="mb-3"
                    >
                      <Form.Control
                        placeholder="Phone"
                        value={this.state.phone}
                        onChange={(text) => {
                          this.setState({
                            phone: text.target.value,
                          });
                        }}
                        style={{
                          width: "100%",
                          height: "60px",
                          border: "1px solid #D5D5D5",
                          borderRadius: "5px",
                        }}
                        tabIndex={20}
                        aria-label="Phone input"
                      ></Form.Control>
                    </FloatingLabel>
                  </Form.Group>
                </div>

                <Dropdown
                  onSelect={(e) => this.handleSelectMethod(e)}
                  aria-label="Preferred method of contact dropdown"
                >
                  <Dropdown.Toggle
                    aria-label="Preferred method of contact dropdown"
                    as={CustomMethodToggle}
                    style={{ width: "100%" }}
                  >
                    {this.state.preferredMethod
                      ? `Method - ${this.state.preferredMethod}`
                      : "Preferred Method of Contact*"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ width: "100%" }}>
                    <Dropdown.Item
                      style={{ fontSize: 16 }}
                      eventKey="Email"
                      tabIndex={22}
                    >
                      Email
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{ fontSize: 16 }}
                      eventKey="Text Message"
                      tabIndex={23}
                    >
                      Text Message
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                  onSelect={(e) => this.handleSelectTopic(e)}
                  aria-label="Select a topic dropdown"
                >
                  <Dropdown.Toggle
                    aria-label="Select a topic dropdown"
                    as={CustomTopicToggle}
                    style={{ width: "100%" }}
                  >
                    {this.state.selectedTopic
                      ? `Topic - ${this.state.selectedTopic}`
                      : "Select a Topic*"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ width: "100%" }}>
                    <Dropdown.Item
                      style={{ fontSize: 15 }}
                      eventKey="Billing"
                      tabIndex={25}
                    >
                      Billing
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{ fontSize: 15 }}
                      eventKey="Complaint"
                      tabIndex={26}
                    >
                      Complaint
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{ fontSize: 15 }}
                      eventKey="Green Pool"
                      tabIndex={27}
                    >
                      Green Pool
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{ fontSize: 15 }}
                      eventKey="New Request"
                      tabIndex={28}
                    >
                      New Request
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{ fontSize: 15 }}
                      eventKey="Question"
                      tabIndex={29}
                    >
                      Question
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{ fontSize: 15 }}
                      eventKey="Service"
                      tabIndex={30}
                    >
                      Service
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Form.Group className="form_item">
                  <FloatingLabel
                    controlId="floatingCommentsInput"
                    label="Comments/Questions*"
                    className="mb-3"
                  >
                    <Form.Control
                      placeholder="Comments/Questions"
                      value={this.state.commentsQuestions}
                      onChange={(text) => {
                        this.setState({
                          commentsQuestions: text.target.value,
                        });
                      }}
                      as="textarea"
                      rows={3}
                      style={{
                        width: "100%",
                        height: "175px",
                        border: "1px solid #D5D5D5",
                        borderRadius: "5px",
                      }}
                      tabIndex={31}
                      aria-label="Comments/Questions input"
                    ></Form.Control>
                  </FloatingLabel>
                </Form.Group>
                <p className="contactSubHeader" style={{ marginTop: 30 }}>
                  ADD IMAGES
                </p>
                <Form.Group controlId="formFileMultiple" className="mb-3">
                  <Form.Control
                    type="file"
                    size="lg"
                    tabIndex={34}
                    onChange={this.handleFileInputChange}
                    multiple
                    style={{ marginBottom: 40 }}
                    accept="image/jpeg, image/png, image/jpg"
                  />
                </Form.Group>
                {!this.state.firstName ||
                !this.state.lastName ||
                !this.state.email ||
                !this.state.phone ||
                !this.state.preferredMethod ||
                !this.state.selectedTopic ||
                !this.state.commentsQuestions ? (
                  <AppGreyButton
                    text="Please fill fields"
                    arialabel="Submit button - disabled due to required inputs"
                  />
                ) : (
                  <AppButton
                    text="SUBMIT"
                    onClick={() => this.onSubmit()}
                    tabindex={32}
                    arialabel="Submit button"
                  />
                )}
              </div>
              <div className="contactRightContainer">
                {/* <AppCardButton text="Give your feedback" icon={feedbackIcon} /> */}
                {/* <AppCardButton text="Refer a friend" icon={feedbackIcon} /> */}
                <p
                  className="contactSubHeader"
                  style={{ letterSpacing: "1.02px" }}
                >
                  CALL CENTER
                </p>
                <div style={{ marginTop: "25px", marginBottom: "25px" }}>
                  <p
                    style={{
                      fontFamily: "museo-sans",
                      fontSize: "16px/22px",
                      fontWeight: "700",
                      marginBottom: "0px",
                    }}
                  >
                    Hours of operation:
                  </p>
                  <p
                    style={{
                      fontFamily: "museo-sans",
                      fontSize: "16px/22px",
                      fontWeight: "700",
                    }}
                  >
                    7AM - 4PM MST
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingBottom: "50px",
                  }}
                >
                  <img
                    src={phoneIcon}
                    alt="Phone Icon"
                    style={{ height: "30px", marginRight: "10px" }}
                  />
                  <p
                    style={{
                      fontFamily: "museo-sans",
                      fontSize: "24px",
                      fontWeight: "700",
                      marginBottom: "0px",
                    }}
                  >
                    1-800-990-7440
                  </p>
                </div>
              </div>
            </div>
            <div
              className="termsAndPrivacyContainerPayments"
              style={{ display: "flex", flexDirection: "row", marginTop: 100 }}
            >
              <PrivacyPolicy color="black" tabindex={34} />
              <p
                style={{
                  color: "black",
                  fontWeight: "500",
                  marginRight: "10px",
                  fontSize: "13px",
                }}
              >
                |
              </p>
              <TermsAndPrivacy color="black" tabindex={35} />
            </div>
          </>
        )}
        {this.state.showSubmissionResponseModal && (
          <Modal
            show={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName="custom-modal-dashboard"
            style={{ borderRadius: "25px" }}
          >
            <Modal.Body>
              <></>
            </Modal.Body>
          </Modal>
        )}
        {this.state.showLoadErrorModal && <LoadErrorModal />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(Contact);
