/** @format */

import React, { PureComponent } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import {
  GLOBAL_DOMAIN,
  // GLOBAL_ENVIRONMENT,
  GLOBAL_SF_DOMAIN,
} from "../../actions/types";
import {
  addToken,
  onLIEmailChange,
  onLIPasswordChange,
  attemptLogin,
  onLIError,
  onASNameChange,
  onASClearFields,
} from "../../actions";
import "../../css/style.scss";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Button, Modal } from "react-bootstrap";

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      forgotPasswordClicked: false,
      forgotPasswordMessage: "",
      redirectURL: "",
      showModal: false,
      modalMessage: "",
    };
  }

  async componentDidMount() {
    this.logIn();
  }

  async logIn() {
    let url = window.location.href;
    let token = "";
    let userData = {};

    // IF A CODE IS IN THE URL, GET THE TOKEN USING THE CODE THEN GET USER INFO
    if (url.includes("code=") && url.includes("login")) {
      try {
        let params = new URL(document.location).searchParams;
        let code = params.get("code");
        localStorage.setItem("code", code);
        // console.log("code", code);
        // console.log(`HIT USERINFOTOKEN`);
        let checkTokenResponse = await axios.get(
          `${GLOBAL_DOMAIN}/api/auth/userInfoToken`,
          {
            params: {
              code,
              baseDomain: `${GLOBAL_SF_DOMAIN}`,
            },
          }
        );
        // console.log("checkTokenResponse", checkTokenResponse);
        token = checkTokenResponse?.data;
        localStorage.setItem("token", token);
        // console.log("token", token);

        // console.log(`getUserInfo`);
        let checkUserResponse = await axios.get(
          `${GLOBAL_DOMAIN}/api/auth/userInfoSF`,
          {
            params: {
              token,
              baseDomain: `${GLOBAL_SF_DOMAIN}`,
            },
          }
        );
        userData = checkUserResponse?.data;
        // console.log(checkUserResponse);
      } catch (err) {
        console.log(err);
      }
      // IF NO CODE IS ACTIVE, INITIALIZE REDIRECT
    } else if (
      // ENSURE WE ARE ON THE CORRECT PAGE TO CALL THE REDIRECT
      url === "http://localhost:3000/" ||
      url === "http://localhost:3000/login" ||
      url === "https://jazzy-tarsier-1f5614.netlify.app/" ||
      url === "https://jazzy-tarsier-1f5614.netlify.app/login" ||
      url === "https://staging--jazzy-tarsier-1f5614.netlify.app/" ||
      url === "https://staging--jazzy-tarsier-1f5614.netlify.app/login" ||
      url === "https://portal.amenitypool.com/" ||
      url === "https://portal.amenitypool.com/login" ||
      url === "portal.amenitypool.com" ||
      url === "portal.amenitypool.com/login" ||
      url.includes("_gl=")
    ) {
      // SANDBOX URLS
      // if (
      //   (url.includes("localhost") || url.includes("staging")) &&
      //   GLOBAL_ENVIRONMENT !== "PROD"
      // ) {
      //   if (url.includes("localhost")) {
      //     return (window.location.href =
      //       "https://ap-maintenance--pmanpart.sandbox.my.site.com/services/oauth2/authorize?client_id=3MVG9hq7jmfCuKffxV8EIa4PjxaHDKF8_f_kfCcEGRsuXcfPJkPkgKVYUWV_4SjSJOENLOr.ccXKvSRDsCIYh&redirect_uri=https://localhost:3000/login&scope=refresh_token%20api%20web&response_type=code&prompt=login");
      //   } else if (url.includes("amenitypool")) {
      //     return (window.location.href =
      //       "https://ap-maintenance--pmanpart.sandbox.my.site.com/services/oauth2/authorize?client_id=3MVG9hq7jmfCuKffxV8EIa4PjxaHDKF8_f_kfCcEGRsuXcfPJkPkgKVYUWV_4SjSJOENLOr.ccXKvSRDsCIYh&redirect_uri=https://amenitypool.com/login&scope=refresh_token%20api%20web&response_type=code&prompt=login");
      //   } else if (url.includes("staging")) {
      //     return (window.location.href =
      //       "https://ap-maintenance--pmanpart.sandbox.my.site.com/services/oauth2/authorize?client_id=3MVG9hq7jmfCuKffxV8EIa4PjxaHDKF8_f_kfCcEGRsuXcfPJkPkgKVYUWV_4SjSJOENLOr.ccXKvSRDsCIYh&redirect_uri=https://staging--jazzy-tarsier-1f5614.netlify.app/login&scope=refresh_token%20api%20web&response_type=code&prompt=login");
      //   } else {
      //     return (window.location.href =
      //       "https://ap-maintenance--pmanpart.sandbox.my.site.com/services/oauth2/authorize?client_id=3MVG9hq7jmfCuKffxV8EIa4PjxaHDKF8_f_kfCcEGRsuXcfPJkPkgKVYUWV_4SjSJOENLOr.ccXKvSRDsCIYh&redirect_uri=https://jazzy-tarsier-1f5614.netlify.app/login&scope=refresh_token%20api%20web&response_type=code&prompt=login");
      //   }
      // } else {
      // PRODUCTION URLS
      if (url.includes("localhost")) {
        return (window.location.href =
          "https://ap-maintenance.my.site.com/services/oauth2/authorize?client_id=3MVG95jctIhbyCpoDXoOMN6Zo3hQWu.NDBKiVWotN5MR1ktCOF02Wy4VqcKifHbiC9GVDMkS3g5l4Gtwd5K1s&redirect_uri=https://localhost:3000/login&scope=refresh_token%20api%20web&response_type=code&prompt=login");
      } else if (url.includes("amenitypool")) {
        return (window.location.href =
          "https://ap-maintenance.my.site.com/services/oauth2/authorize?client_id=3MVG95jctIhbyCpoDXoOMN6Zo3hQWu.NDBKiVWotN5MR1ktCOF02Wy4VqcKifHbiC9GVDMkS3g5l4Gtwd5K1s&redirect_uri=https://portal.amenitypool.com/login&scope=refresh_token%20api%20web&response_type=code&prompt=login");
      } else if (url.includes("staging")) {
        return (window.location.href =
          "https://ap-maintenance.my.site.com/services/oauth2/authorize?client_id=3MVG95jctIhbyCpoDXoOMN6Zo3hQWu.NDBKiVWotN5MR1ktCOF02Wy4VqcKifHbiC9GVDMkS3g5l4Gtwd5K1s&redirect_uri=https://staging--jazzy-tarsier-1f5614.netlify.app/login&scope=refresh_token%20api%20web&response_type=code&prompt=login");
      } else {
        return (window.location.href =
          "https://ap-maintenance.my.site.com/services/oauth2/authorize?client_id=3MVG95jctIhbyCpoDXoOMN6Zo3hQWu.NDBKiVWotN5MR1ktCOF02Wy4VqcKifHbiC9GVDMkS3g5l4Gtwd5K1s&redirect_uri=https://jazzy-tarsier-1f5614.netlify.app/login&scope=refresh_token%20api%20web&response_type=code&prompt=login");
      }
      // }
    }

    // FORMAT THE CONTACT ID RETURNED FROM PREVIOUS CALL
    // console.log("userData", userData);
    let input = userData?.custom_attributes?.Contact;
    // console.log("Login contact", userData);
    let output = "";
    var addon = "";
    // console.log("INPUT", input);
    if (input.length === 15) {
      for (var block = 0; block < 3; block++) {
        var loop = 0;
        for (var position = 0; position < 5; position++) {
          var current = input.charAt(block * 5 + position);
          if (current >= "A" && current <= "Z") loop += 1 << position;
        }
        addon += "ABCDEFGHIJKLMNOPQRSTUVWXYZ012345".charAt(loop);
      }
      output = input + addon;
    } else {
      output = input;
    }
    // console.log("Output", output);
    // console.log("token", token);
    // console.log("HIT GET CONTACT");
    // GET CONTACT TO RETURN ACCOUNT ID
    let getContact = await axios.get(
      `${GLOBAL_DOMAIN}/api/new/services/contact`,
      {
        params: {
          Id: output,
          token: token,
          baseDomain: window.location.href,
        },
      }
    );
    let contactData = getContact?.data?.records;
    // console.log("loginGetContact", getContact);

    this.props.addToken(token);
    localStorage.removeItem("signedUp");
    localStorage.setItem("accountId", contactData[0]?.AccountId);
    localStorage.setItem("contactId", output);
    localStorage.setItem("region", userData?.address?.region);

    const displayName = localStorage.getItem("displayName");
    const accountId = localStorage.getItem("accountId") || "";
    const contactId = localStorage.getItem("contactId");
    const region = localStorage.getItem("region");

    if (contactId !== "undefined") {
      this.props.onASNameChange(displayName, accountId, contactId, region);
    }

    this.props.onASNameChange(
      userData.given_name,
      output,
      output,
      userData.address.region
    );
    if (url.includes("localhost")) {
      return (window.location.href = "http://localhost:3000/Dashboard");
    } else if (url.includes("amenitypool")) {
      return (window.location.href =
        "https://portal.amenitypool.com/Dashboard");
    } else if (url.includes("staging")) {
      return (window.location.href =
        "https://staging--jazzy-tarsier-1f5614.netlify.app/Dashboard");
    } else {
      return (window.location.href =
        "https://jazzy-tarsier-1f5614.netlify.app/Dashboard");
    }
  }

  async logOut() {
    const token = localStorage.getItem("token");
    localStorage.removeItem("token");
    try {
      await axios.get(`${GLOBAL_DOMAIN}/api/auth/logout`, {
        params: {
          token,
          baseDomain: window.location.href,
        },
      });
      // console.log("logoutResponse", logoutResponse);
      localStorage.removeItem("contactId");
      localStorage.removeItem("accountId");
      localStorage.removeItem("code");
      localStorage.removeItem("region");
      localStorage.removeItem("dashboardMessageSeen");
      onASClearFields();
    } catch (err) {
      console.log(err);
    }
  }

  handleClose = () => this.setState({ showModal: false });
  render() {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <Modal
          show={this.state.showModal}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          contentClassName="custom-modal-dashboard"
          style={{ borderRadius: "25px" }}
        >
          <Modal.Body>
            <>
              <div
                style={{ display: "flex", justifyContent: "flex-end" }}
                onClick={() => this.setState({ showModal: false })}
                aria-label="Close modal"
              >
                <IoIosCloseCircleOutline
                  size={50}
                  style={{ marginBottom: "25px" }}
                  color="#d44203"
                />
              </div>
              <p
                style={{
                  fontSize: "25px",
                  fontFamily: "Oswald",
                  color: "#d44203",
                  fontWeight: "300",
                  lineHeight: "30px",
                  marginBottom: "50px",
                }}
              >
                {this.state.modalMessage}
              </p>
              {this.state.modalMessage ===
                "Issue getting auth token, please refresh page. If issue persists please contact support" && (
                <Button
                  aria-label="Log Out"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    display: "flex",
                  }}
                  className="logout whiteUnderlineHover"
                  href="/logout"
                  variant="none"
                  onClick={() => {
                    this.logOut();
                  }}
                >
                  <p
                    style={{
                      fontSize: "25px",
                      fontFamily: "Oswald",
                      color: "#d44203",
                      fontWeight: "700",
                      lineHeight: "30px",
                      marginBottom: "50px",
                      textDecorationLine: "underline",
                    }}
                  >
                    LOGOUT
                  </p>
                </Button>
              )}
            </>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    email: state.auth.email,
    password: state.auth.password,
    loginError: state.auth.error,
    token: state.auth.token,
    loading: state.auth.loading,
    isLoggedIn: !!state.account.contactId,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    addToken,
    onASNameChange,
    onLIEmailChange,
    onLIPasswordChange,
    onLIError,
    attemptLogin,
  })(Login)
);
