import React, { PureComponent } from "react";
import { Image } from "react-bootstrap";

import { connect } from "react-redux";
import loginMobileLogos from "../../assets/logos/amenityServicesLogo.png";
import loginDesktopLogos from "../../assets/logos/amenityServicesLogo.png";
// import { Link } from "react-router-dom";

class LoginImage extends PureComponent {
  render() {
    return (
      <>
        {/* <div style={{ height: "100%" }}> */}
        {/* <LogoutNavbar /> */}
        {/* <div className="routeNotFoundContainer"> */}
        <div>
          <div className="loginImageDesktop">
            <div
              style={{
                height: "100%",
                maxWidth: "60%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  minHeight: "50vh",
                  maxHeight: "80vh",
                }}
              >
                <Image
                  style={{
                    width: "400px",
                    height: "120px",
                    marginBottom: "75px",
                  }}
                  alt="Login Logo Mobile"
                  src={amenityServicesLogo}
                ></Image>
                <Image
                  style={{
                    width: "450px",
                    height: "100px",
                    marginBottom: "50px",
                  }}
                  alt="Login Logo Mobile"
                  src={allFlorida}
                ></Image>
                <Image
                  style={{
                    width: "375px",
                    height: "150px",
                    marginBottom: "50px",
                  }}
                  alt="Login Logo Mobile"
                  src={allPool}
                ></Image>
                <Image
                  style={{ width: "400px", height: "150px" }}
                  alt="Login Logo Mobile"
                  src={blueWater}
                ></Image>
                <h1
                  className="TACLoginStatement"
                  style={{ color: "white", marginTop: "100px" }}
                >
                  The Amenity Collective
                </h1>
                <h2 className="TACLoginStatement" style={{ color: "white" }}>
                  Lifestyle Services That Make People Smile
                </h2>
              </div> */}
              <Image
                style={{
                  // minWidth: "300px",
                  width: "auto",
                  maxHeight: "auto",
                  marginLeft: 25,
                }}
                alt="Login Logo Desktop"
                src={loginDesktopLogos}
              ></Image>
              {/* <Link
                className="loginLink"
                to={`/TermsAndConditions`}
                style={{
                  textAlign: "center",
                  color: "white",
                }}
              >
                <p
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    fontFamily: "museo-sans",
                    fontSize: 16,
                  }}
                >
                  The Amenity Collective
                </p>
                <p style={{ fontFamily: "museo-sans", fontSize: 16 }}>
                  Lifestyle Services that Make People Smile
                </p>
              </Link> */}
            </div>
          </div>
          <div className="loginImageMobile">
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Image
                style={{
                  // minWidth: "300px",
                  width: "85%",
                  maxHeight: "100%",
                }}
                alt="Login Logo Mobile"
                src={loginMobileLogos}
              ></Image>
              {/* <Link
                className="loginLink"
                to={`/TermsAndConditions`}
                style={{
                  textAlign: "center",
                  color: "white",
                }}
              >
                <p
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    fontFamily: "museo-sans",
                    fontSize: 16,
                  }}
                >
                  The Amenity Collective
                </p>
                <p style={{ fontFamily: "museo-sans", fontSize: 16 }}>
                  Lifestyle Services that Make People Smile
                </p>
              </Link> */}
            </div>
          </div>
        </div>
        {/* <Link to={"/login"} tabIndex={17}>
                <AppButton text="Return to login" />
              </Link> */}
        {/* <div><TermsAndPrivacy color="#727272" tabindex={18} /></div> */}
        {/* </div> */}
        {/* </div> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(LoginImage);
