import React, { PureComponent } from "react";
import {
  GLOBAL_DOMAIN,
  GLOBAL_SF_DOMAIN,
  GLOBAL_WEB_DOMAIN,
} from "../../actions/types";
import axios from "axios";

import { connect } from "react-redux";
import AppButton from "../CustomComponents/AppButton";
import AppNavbarWave from "../CustomComponents/AppNavbarWave";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import TermsAndPrivacy from "../CustomComponents/TermsAndPrivacy";
import { Spinner } from "react-bootstrap";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";
import { Link } from "react-router-dom";
import PrivacyPolicy from "../CustomComponents/PrivacyPolicy";
class EditAccountDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoadErrorModal: false,
      addressOne: "",
      addressTwo: "",
      city: "",
      state: "",
      zip: "",
      email: "",
      phone: "",
      showSpinner: false,
      showUpdateResponse: false,
      updateDetailsResponseSuccess: false,
      userId: "",
    };
  }
  componentDidMount = async () => {
    let accountID = localStorage.getItem("accountId");
    let contactID = localStorage.getItem("contactId");
    if (!accountID && !contactID) {
      window.location.href = `${GLOBAL_SF_DOMAIN}/services/oauth2/authorize?client_id=3MVG9hq7jmfCuKfcfEvXMNxD433CfsCPKAYx8ArK6TiGKw1aiwZ6qkalCe7mrAraIjbIxajp2kecEnIMR1U40&redirect_uri=https://${GLOBAL_WEB_DOMAIN}/login&scope=refresh_token%20api%20web&response_type=code&prompt=login`;
    } else {
      try {
        let getContact = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/services/contact`,
          {
            params: {
              Id: localStorage.getItem("contactId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );
        this.setState({});
        let getAccount = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/services/account`,
          {
            params: {
              Id: localStorage.getItem("accountId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );
        let getUser = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/services/user`,
          {
            params: {
              Id: localStorage.getItem("accountId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );
        let data = getContact?.data?.records;
        let accountData = getAccount?.data?.records;
        if (accountData) {
          this.setState({
            userId: getUser?.data?.records[0]?.Id,
            addressOne: accountData[0]?.BillingAddress?.street,
            city: accountData[0]?.BillingAddress?.city,
            state: accountData[0]?.BillingAddress.state,
            zip: accountData[0]?.BillingAddress.postalCode,
            email: data[0]?.Email,
            phone: data[0]?.Phone,
          });
        } else {
          this.setState({ showLoadErrorModal: true });
        }
        // console.log(data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  onSubmit = async () => {
    this.setState({ showSpinner: true });
    window.scrollTo(0, 0);
    let token = localStorage.getItem("token");
    let accountId = localStorage.getItem("accountId");
    let contactId = localStorage.getItem("contactId");
    let submitEditAccount = await axios.get(
      `${GLOBAL_DOMAIN}/api/new/services/editAccountDetails`,
      {
        params: {
          data: this.state,
          token: token,
          accountId,
          contactId,
          userId: this.state.userId,
          baseDomain: window.location.href,
        },
      }
    );
    // console.log("Hit", submitEditAccount);
    if (submitEditAccount?.data?.success) {
      this.setState({ updateDetailsResponseSuccess: true });
    } else {
      this.setState({ updateDetailsResponseSuccess: false });
    }
    this.setState({ showUpdateResponse: true, showSpinner: false });
  };

  render() {
    return (
      <>
        <AppNavbarWave />
        {this.state.showSpinner ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "250px",
              flexDirection: "column",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p
              style={{
                fontFamily: "museo-sans",
                marginTop: 10,
                textAlign: "center",
                marginLeft: "5%",
                marginRight: "5%",
                maxWidth: "90%",
              }}
            >
              Currently processing, this can take up to a minute—please do not
              close this window or exit your browser until you get a
              confirmation.
            </p>
          </div>
        ) : (
          <>
            {this.state.showUpdateResponse ? (
              <>
                <div className="editAccountContentContainer">
                  <h1
                    className="myAccountHeader"
                    style={{
                      fontSize: "42px",
                      color: "#1A4EA2",
                      fontFamily: "Oswald",
                      fontWeight: "300",
                    }}
                  >
                    Edit Account Details
                  </h1>
                </div>
                {this.state.updateDetailsResponseSuccess ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "50px",
                      marginBottom: "50px",
                      flexDirection: "column",
                    }}
                  >
                    <RxCheckCircled
                      size={55}
                      color="#1A4EA2"
                      style={{ marginRight: "10px" }}
                    />
                    <p
                      style={{
                        fontSize: "22px",
                        fontWeight: "700",
                        margin: "20px",
                        textAlign: "center",
                      }}
                    >
                      Details updated successfully
                    </p>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "50px",
                      marginBottom: "50px",
                      flexDirection: "column",
                    }}
                  >
                    <RxCrossCircled
                      size={55}
                      color="#D54100"
                      style={{ marginRight: "10px" }}
                    />
                    <p
                      style={{
                        fontSize: "22px",
                        fontWeight: "700",
                        margin: "20px",
                        color: "#D54100",
                        textAlign: "center",
                      }}
                    >
                      Error updating your account details
                    </p>
                  </div>
                )}
                <Link
                  to={"/AccountDetails"}
                  className="returnHomeLink"
                  style={{
                    color: "#1A4EA2",
                    marginBottom: "100px",
                  }}
                  tabIndex={17}
                  aria-label="Return to account details"
                >
                  RETURN TO ACCOUNT DETAILS
                </Link>
              </>
            ) : (
              <>
                <div className="editAccountContentContainer">
                  <h1
                    className="myAccountHeader"
                    style={{
                      fontSize: "42px",
                      color: "#1A4EA2",
                      fontFamily: "Oswald",
                      fontWeight: "300",
                    }}
                  >
                    Edit Account Details
                  </h1>
                  <p
                    className="editAccountInfoText"
                    style={{
                      fontSize: "16px",
                      fontWeight: "300",
                    }}
                  >
                    If your billing address or contact info is changing, please
                    update it below. If you wish to change your email, this will
                    also affect your login for this portal and you must confirm
                    the change by clicking the link on the email you receive at
                    the new address
                  </p>
                  <p className="editAccountSubHeader">BILLING ADDRESS</p>
                  <Form.Group
                    className="form_item"
                    style={{ width: "90%", marginTop: "30px" }}
                  >
                    <FloatingLabel
                      controlId="floatingAddressOneInput"
                      label="Address 1"
                      className="mb-3 editAccountFormLong"
                    >
                      <Form.Control
                        placeholder="Address 1"
                        value={this.state.addressOne}
                        onChange={(text) => {
                          this.setState({
                            addressOne: text.target.value,
                          });
                        }}
                        style={{
                          width: "100%",
                          height: "60px",
                          border: "1px solid #D5D5D5",
                          borderRadius: "5px",
                        }}
                        tabIndex={17}
                        aria-label="Address 1 input"
                      ></Form.Control>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group
                    className="form_item"
                    style={{ width: "fit-content" }}
                  >
                    <FloatingLabel
                      controlId="floatingAddressTwoInput"
                      label="Address 2"
                      className="mb-3"
                    >
                      <Form.Control
                        placeholder="Adress 2"
                        value={this.state.addressTwo}
                        onChange={(text) => {
                          this.setState({
                            addressTwo: text.target.value,
                          });
                        }}
                        style={{
                          width: "100%",
                          height: "60px",
                          border: "1px solid #D5D5D5",
                          borderRadius: "5px",
                        }}
                        tabIndex={18}
                        aria-label="Address 2 input"
                      ></Form.Control>
                    </FloatingLabel>
                  </Form.Group>
                  <div className="editAccountFlexInputsContainer">
                    <Form.Group
                      className="form_item"
                      style={{ marginRight: "20px" }}
                    >
                      <FloatingLabel
                        controlId="floatingCityInput"
                        label="City"
                        className="mb-3"
                      >
                        <Form.Control
                          placeholder="City"
                          value={this.state.city}
                          onChange={(text) => {
                            this.setState({
                              city: text.target.value,
                            });
                          }}
                          style={{
                            width: "100%",
                            height: "60px",
                            border: "1px solid #D5D5D5",
                            borderRadius: "5px",
                          }}
                          tabIndex={19}
                          aria-label="City input"
                        ></Form.Control>
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group
                      className="form_item"
                      style={{ width: "fit-content" }}
                    >
                      <FloatingLabel
                        controlId="floatingStateInput"
                        label="State"
                        className="mb-3"
                      >
                        <Form.Control
                          placeholder="State"
                          value={this.state.state}
                          onChange={(text) => {
                            this.setState({
                              state: text.target.value,
                            });
                          }}
                          style={{
                            width: "100%",
                            height: "60px",
                            border: "1px solid #D5D5D5",
                            borderRadius: "5px",
                          }}
                          tabIndex={20}
                          aria-label="State input"
                        ></Form.Control>
                      </FloatingLabel>
                    </Form.Group>
                  </div>
                  <Form.Group
                    className="form_item"
                    style={{ width: "fit-content" }}
                  >
                    <FloatingLabel
                      controlId="floatingZipInput"
                      label="ZIP"
                      className="mb-3"
                    >
                      <Form.Control
                        placeholder="ZIP"
                        value={this.state.zip}
                        onChange={(text) => {
                          this.setState({
                            zip: text.target.value,
                          });
                        }}
                        style={{
                          width: "100%",
                          height: "60px",
                          border: "1px solid #D5D5D5",
                          borderRadius: "5px",
                        }}
                        tabIndex={21}
                        aria-label="ZIP input"
                      ></Form.Control>
                    </FloatingLabel>
                  </Form.Group>
                  <p
                    style={{
                      color: "#939393",
                      fontWeight: "700",
                      fontSize: "18px",
                      textAlign: "left",
                      letterSpacing: "1px",
                      marginTop: 50,
                      borderBottom: "1px solid #E9E9E9",
                      marginBottom: "30px",
                    }}
                  >
                    {/* PHONE & EMAIL */}
                    PHONE
                  </p>
                  <p
                    className="editAccountInfoText"
                    style={{
                      fontSize: "16px",
                      fontWeight: "300",
                    }}
                  >
                    NOTE: To change the email address associated to your
                    account, please navigate to the Contact screen and fill out
                    a "New Request" form.
                  </p>
                  <div className="editAccountFlexInputsContainer">
                    {/* <Form.Group
                      className="form_item"
                      style={{ marginRight: "20px" }}
                    >
                      <FloatingLabel
                        controlId="floatingEmailInput"
                        label="Email / Username"
                        className="mb-3"
                      >
                        <Form.Control
                          placeholder="Email"
                          value={this.state.email}
                          onChange={(text) => {
                            this.setState({
                              email: text.target.value,
                            });
                          }}
                          style={{
                            width: "100%",
                            height: "60px",
                            border: "1px solid #D5D5D5",
                            borderRadius: "5px",
                          }}
                          tabIndex={22}
                          aria-label="Email input"
                        ></Form.Control>
                      </FloatingLabel>
                    </Form.Group> */}
                    <Form.Group
                      className="form_item"
                      style={{ width: "fit-content" }}
                    >
                      <FloatingLabel
                        controlId="floatingPhoneInput"
                        label="Phone"
                        className="mb-3"
                      >
                        <Form.Control
                          placeholder="Phone"
                          value={this.state.phone}
                          onChange={(text) => {
                            this.setState({
                              phone: text.target.value,
                            });
                          }}
                          style={{
                            width: "100%",
                            height: "60px",
                            border: "1px solid #D5D5D5",
                            borderRadius: "5px",
                          }}
                          tabIndex={23}
                          aria-label="Phone input"
                        ></Form.Control>
                      </FloatingLabel>
                    </Form.Group>
                  </div>
                  <AppButton
                    text="SUBMIT"
                    onClick={() => this.onSubmit()}
                    tabindex={24}
                    arialabel="Submit account details update"
                  />
                </div>
              </>
            )}
          </>
        )}
        <div
          className="editAccountContentContainer"
          style={{ display: "flex", flexDirection: "row", marginTop: 100 }}
        >
          <PrivacyPolicy color="black" tabindex={32} />
          <p
            style={{
              color: "black",
              fontWeight: "500",
              marginRight: "10px",
              fontSize: "13px",
            }}
          >
            |
          </p>
          <TermsAndPrivacy color="black" tabindex={33} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(EditAccountDetails);
