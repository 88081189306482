import React, { PureComponent } from "react";
import "../../css/style.scss";

import Button from "react-bootstrap/Button";

class AppGhostButtonDesktop extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Button
        // className="appButton"
        style={{
          marginTop: "10px",
          marginBottom: "30px",
          color: "#FFE160",
          backgroundColor: "transparent",
          border: "2px solid",
          borderRadius: 25,
          width: "fit-content",
          fontSize: "20px",
          letterSpacing: "2.5px",
          // marginBottom: "30px",
          paddingRight: "40px",
          paddingLeft: "40px",
          fontWeight: "700",
          fontFamily: "museo-sans",
          opacity: 1,
        }}
        tabIndex={17}
        type="Submit"
        onClick={this.props.onClick}
        aria-label={this.props.arialabel}
        disabled
      >
        {this.props.text}
      </Button>
    );
  }
}

export default AppGhostButtonDesktop;
