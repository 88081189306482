import React, { PureComponent } from "react";
import "../../css/style.scss";

import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import { onASClearFields } from "../../reducers/AccountSetupReducer";
import axios from "axios";
import { GLOBAL_DOMAIN } from "../../actions/types";

class LoadErrorModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async logOut() {
    const token = localStorage.getItem("token");
    localStorage.removeItem("token");
    try {
      await axios.get(`${GLOBAL_DOMAIN}/api/auth/logout`, {
        params: {
          token,
          baseDomain: window.location.href,
        },
      });
      // console.log("logoutResponse", logoutResponse);
      localStorage.removeItem("contactId");
      localStorage.removeItem("accountId");
      localStorage.removeItem("code");
      localStorage.removeItem("dashboardMessageSeen");
      onASClearFields();
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <Modal
        show={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="custom-modal-dashboard"
        style={{ borderRadius: "25px" }}
      >
        <Modal.Body>
          <>
            <p
              style={{
                fontSize: "25px",
                fontFamily: "Oswald",
                color: "#d44203",
                fontWeight: "300",
                lineHeight: "30px",
                marginBottom: "50px",
                marginTop: "50px",
              }}
            >
              Error retrieving data, please logout and try again
            </p>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                aria-label="Log Out"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  display: "flex",
                }}
                className="logout whiteUnderlineHover"
                href="/login"
                variant="none"
                onClick={() => {
                  this.logOut();
                }}
              >
                <p
                  style={{
                    fontSize: "25px",
                    fontFamily: "Oswald",
                    color: "#d44203",
                    fontWeight: "700",
                    lineHeight: "30px",
                    marginBottom: "50px",
                    textDecorationLine: "underline",
                  }}
                >
                  LOG OUT
                </p>
              </Button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    );
  }
}

export default LoadErrorModal;
