import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppNavbarWave from "../CustomComponents/AppNavbarWave";
import axios from "axios";
import {
  GLOBAL_DOMAIN,
  GLOBAL_SF_DOMAIN,
  GLOBAL_WEB_DOMAIN,
} from "../../actions/types";
import LoadErrorModal from "../CustomComponents/LoadErrorModal";
import Spinner from "react-bootstrap/Spinner";
import AppGhostButton from "../CustomComponents/AppGhostButton";
import AppPastInvoicesAccordian from "../CustomComponents/AppPastInvoicesAccordion";
import TermsAndPrivacy from "../CustomComponents/TermsAndPrivacy";
import PrivacyPolicy from "../CustomComponents/PrivacyPolicy";

class MyInvoices extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoadErrorModal: false,
      recurringDay: "",
      invoicesData: [],
      showMoreThanFive: false,
      spinner: false,
    };
  }
  componentDidMount = async () => {
    let accountID = localStorage.getItem("accountId");
    let contactID = localStorage.getItem("contactId");
    if (!accountID && !contactID) {
      window.location.href = `${GLOBAL_SF_DOMAIN}/services/oauth2/authorize?client_id=3MVG9hq7jmfCuKfcfEvXMNxD433CfsCPKAYx8ArK6TiGKw1aiwZ6qkalCe7mrAraIjbIxajp2kecEnIMR1U40&redirect_uri=https://${GLOBAL_WEB_DOMAIN}/login&scope=refresh_token%20api%20web&response_type=code&prompt=login`;
    } else {
      try {
        this.setState({ spinner: true });
        // GET ALL SERVICES INFO
        let getPreviousInvoices = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/payment/pastInvoices`,
          {
            params: {
              token: localStorage.getItem("token"),
              contactId: localStorage.getItem("contactId"),
              baseDomain: window.location.href,
            },
          }
        );
        let allInvoicesData = getPreviousInvoices?.data?.records;
        // console.log("Invoices", allInvoicesData);
        let getInvoices = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/payment/unpaidInvoices`,
          {
            params: {
              contactId: localStorage.getItem("contactId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );

        let invoices = getInvoices.data.records;
        // console.log("Upaid Invoices", invoices);
        if (invoices?.length) {
          for (let i = 0; i < invoices.length; i++) {
            // CHECK FOR PENDING PAYMENTS MADE ON INVOICE
            if (invoices[i]?.Invoice_Payments__r?.records?.length) {
              let paymentRecords = invoices[i]?.Invoice_Payments__r?.records;
              // IF PAYMENT RECORDS EXIST, CHECK RECORD
              for (let j = 0; j < paymentRecords.length; j++) {
                // IF CURRENTLY PROCESSING TRANSACTION
                if (
                  paymentRecords[j]?.pymt__Status__c === "Open" ||
                  "Processing" ||
                  "Pending"
                ) {
                  // CHECK IF THE PROCESSING TRANACTION IS CURRENT
                  const timestamp = new Date(
                    paymentRecords[j]?.CreatedDate
                  ).getTime();
                  if (!isNaN(timestamp)) {
                    const timestampDatetime = new Date(timestamp);
                    const currentDatetime = new Date();
                    const timeDifference = currentDatetime - timestampDatetime;
                    let isWithin24 = timeDifference <= 24 * 60 * 60 * 1000; // 24 hours in milliseconds
                    if (isWithin24) {
                      // IF THERE IS A RECENT PENDING INVOICE TRANSACTION,
                      let invoice = { ...invoices[i], pending: true };
                      allInvoicesData.unshift(invoice);
                      break;
                    }
                  }
                }
              }
            }
          }
        }
        // console.log(allInvoicesData);
        if (allInvoicesData) {
          this.setState({
            invoicesData: allInvoicesData,
            spinner: false,
          });
        } else {
          this.setState({ spinner: false, showLoadErrorModal: true });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  render() {
    return (
      <>
        <AppNavbarWave />
        <div className="myServicesFlexContainer">
          <div className="myServicesContentContainer">
            <h1
              className="myAccountHeader"
              style={{
                fontSize: "42px",
                color: "#1A4EA2",
                fontFamily: "Oswald",
                fontWeight: "300",
              }}
            >
              My Past Invoices
            </h1>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "300",
              }}
            >
              Thanks for being a Poolman client! Here is a list of your previous
              invoices:
            </p>
            <p className="contactSubHeader" style={{ marginTop: 50 }}>
              RECORD OF INVOICES
            </p>

            {this.state.spinner ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 100,
                  marginBottom: 100,
                }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <AppPastInvoicesAccordian
                  invoices={this.state.invoicesData}
                  showMoreThanFive={this.state.showMoreThanFive}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // paddingTop: 25,
                    borderTop: "#E9E9E9 5px solid",
                    marginBottom: "100px",
                  }}
                >
                  {this.state.invoicesData.length > 5 ? (
                    <AppGhostButton
                      text={
                        this.state.showMoreThanFive ? "SHOW LESS" : "LOAD MORE"
                      }
                      onClick={() => {
                        if (this.state.showMoreThanFive) {
                          this.setState({ showMoreThanFive: false });
                        } else {
                          this.setState({ showMoreThanFive: true });
                        }
                      }}
                      tabindex={18}
                      arialabel="Show/Hide additional invoices"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </div>
          {/* <div className="contactRightContainer">
            <AppCardButton
              text="Upgrade or Change Service"
              icon={arrowIcon}
              arialabel="Upgrade or change service"
              iconAlt="Upgrade or change service"
              navigateTo="/MyServices"
              tabindex={19}
            />
            <AppCardButton
              text="Notification Preferences"
              icon={alertIcon}
              arialabel="Notification preferences"
              iconAlt="Notification preferences logo"
              navigateTo={`/AccountDetails/Notifications`}
              tabindex={20}
            />
            <AppCardButton
              text="Request Services"
              icon={serviceIcon}
              arialabel="Request Services"
              iconAlt="Request services icon"
              navigateTo="/Contact"
              tabindex={21}
            />
          </div> */}
        </div>
        {/* <div
          className="termsAndPrivacyContainerPayments"
          style={{ marginTop: 100 }}
        >
          <TermsAndPrivacy color="#727272" tabindex={21} />
        </div> */}
        <div
          className="termsAndPrivacyContainerPayments"
          style={{ display: "flex", flexDirection: "row", marginTop: 100 }}
        >
          <PrivacyPolicy color="black" tabindex={21} />
          <p
            style={{
              color: "black",
              fontWeight: "500",
              marginRight: "10px",
              fontSize: "13px",
            }}
          >
            |
          </p>
          <TermsAndPrivacy color="black" tabindex={22} />
        </div>
        {this.state.showLoadErrorModal && <LoadErrorModal />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(MyInvoices);
