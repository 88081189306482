import React, { PureComponent } from "react";
import { Button } from "react-bootstrap";
import { MdOutlineArrowRight } from "react-icons/md";

import "../../css/style.scss";
import AppSubHeaderText from "./AppSubHeader";

class AppSelectPaymentMethod extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div style={{ marginLeft: "35px" }}>
          <AppSubHeaderText text="PAY WITH" />
        </div>
        <div style={{ display: "flex" }}>
          <Button
            onClick={this.props.onClick}
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "transparent",
              color: "black",
              width: "300px",
              //   height: "45px",

              justifyContent: "space-between",
              borderColor: "#D5D5D5",
              borderRadius: "18px",
              // boxShadow: "1px 1px 6px #00000026",
            }}
            className="AppSelectPaymentMethod"
            tabIndex={19}
            aria-label="Open select payment modal"
          >
            <p
              style={{
                fontSize: "20px",
                fontFamily: "museo-sans",
                fontWeight: "300px",
                marginLeft: "20px",
                marginBottom: "0px",
              }}
            >
              {this.props.selectedCard}
            </p>
            <MdOutlineArrowRight size={30} style={{ marginLeft: "10px" }} />
          </Button>
        </div>
      </div>
    );
  }
}

export default AppSelectPaymentMethod;
