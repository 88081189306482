import React, { PureComponent } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AppNavbarWave from "../CustomComponents/AppNavbarWave";
import TermsAndPrivacy from "../CustomComponents/TermsAndPrivacy";
import { GLOBAL_SF_DOMAIN, GLOBAL_WEB_DOMAIN } from "../../actions/types";
import PrivacyPolicy from "../CustomComponents/PrivacyPolicy";
class GetRepairEstimate extends PureComponent {
  componentDidMount = async () => {
    let accountID = localStorage.getItem("accountId");
    let contactID = localStorage.getItem("contactId");
    if (!accountID && !contactID) {
      window.location.href = `${GLOBAL_SF_DOMAIN}/services/oauth2/authorize?client_id=3MVG9hq7jmfCuKfcfEvXMNxD433CfsCPKAYx8ArK6TiGKw1aiwZ6qkalCe7mrAraIjbIxajp2kecEnIMR1U40&redirect_uri=https://${GLOBAL_WEB_DOMAIN}/login&scope=refresh_token%20api%20web&response_type=code&prompt=login`;
    }
  };
  render() {
    return (
      <div style={{ height: "100%" }}>
        <AppNavbarWave />
        <div className="routeNotFoundContainer">
          <div>
            <h1
              style={{
                fontFamily: "Oswald",
                fontSize: "135px",
                color: "#727272",
                fontWeight: "600",
                marginLeft: "20px",
              }}
            >
              Coming soon..
            </h1>
            <h2
              style={{
                fontSize: "42px",
                color: "#1A4EA2",
                fontFamily: "Oswald",
                fontWeight: "300",
                marginTop: "-25px",
                marginLeft: "20px",
              }}
            >
              Check back later!
            </h2>
            <Link
              to={"/Dashboard"}
              className="returnHomeLink"
              style={{
                color: "#D54100",
              }}
              tabIndex={17}
              aria-label="Return to dashboard"
            >
              RETURN TO HOME PAGE
            </Link>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <PrivacyPolicy color="black" tabindex={32} />
            <p
              style={{
                color: "black",
                fontWeight: "500",
                marginRight: "10px",
                fontSize: "13px",
              }}
            >
              |
            </p>
            <TermsAndPrivacy color="black" tabindex={33} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(GetRepairEstimate);
