import React, { PureComponent } from "react";

import { connect } from "react-redux";
import AppNavbarWave from "../CustomComponents/AppNavbarWave";
class Redirect extends PureComponent {
  render() {
    return (
      <>
        <AppNavbarWave />
        <div>
          <p>Redirect</p>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(Redirect);
