import React, { PureComponent } from "react";

import "../../css/style.scss";

class AppSubHeaderText extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <h1
        className="app-Sub-Header-Text"
        style={{ color: this.props.color ? this.props.color : "#727272" }}
      >
        {this.props.text}
      </h1>
    );
  }
}

export default AppSubHeaderText;
