import React, { PureComponent } from "react";
import "../../css/style.scss";

import Button from "react-bootstrap/Button";

class AppGreyButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Button
        style={{
          marginTop: "10px",
          color: "#727272",
          backgroundColor: "#E9E9E9",
          border: "none",
          borderRadius: 25,
          width: "fit-content",
          fontSize: "20px",
          letterSpacing: "2.5px",
          marginBottom: "30px",
          paddingRight: "40px",
          paddingLeft: "40px",
          fontWeight: "700",
          fontFamily: "museo-sans",
        }}
        type="Submit"
        aria-label={this.props.arialabel}
      >
        {this.props.text}
      </Button>
    );
  }
}

export default AppGreyButton;
