import React, { PureComponent } from "react";

import { withRouter } from "react-router-dom";
import AppButton from "../CustomComponents/AppButton";
// import axios from "axios";
// import { GLOBAL_DOMAIN } from "../../actions/types";

// async function getLinvioPayMethods() {
//   const items = [{ id: "p1" }];
//   let response = await axios.get(
//     `${GLOBAL_DOMAIN}/api/new/services/get-payment-methods`,
//     {
//       params: {
//         body: JSON.stringify({ items }),
//         baseDomain: window.location.href,
//       },
//       headers: { "Content-Type": "application/json" },
//     }
//   );
//   console.log("pay methods response", response);
//   return await response.data;
// }

// async function createLinvioPayMethods() {
//   const items = [{ id: "p1" }];
//   let response = await axios.get(
//     `${GLOBAL_DOMAIN}/api/new/services/create-payment-method`,
//     {
//       params: {
//         body: JSON.stringify({ items }),
//         baseDomain: window.location.href,
//       },
//       headers: { "Content-Type": "application/json" },
//     }
//   );
//   console.log("create method response", response);
//   return await response.data;
// }

// async function captureLinvioPayments(id) {
//   const items = [{ id }];
//   let response = await axios.get(
//     `${GLOBAL_DOMAIN}/api/new/services/capture-payments`,
//     {
//       params: {
//         body: JSON.stringify({ items }),
//         baseDomain: window.location.href,
//       },
//       headers: { "Content-Type": "application/json" },
//     }
//   );
//   return await response.data;
// }
// async function getLinvioPayPayment() {
//   let obj = {
//     contact: "b3cf6af4-0b42-4c9d-9b53-92788667d499",
//     invoiceId: "a3D2C000000Tl1fUAC",
//     amount: "100",
//   };
//   let response = await axios.get(
//     `${GLOBAL_DOMAIN}/api/new/services/create-payment-for-invoice`,
//     {
//       params: {
//         body: JSON.stringify({ obj }),
//         baseDomain: window.location.href,
//       },
//       headers: { "Content-Type": "application/json" },
//     }
//   );
//   console.log("createPaymentResponse", response);
//   return await response.data;
// }
async function initPayInvoice() {
  // console.log("initPayInvoice");
  // const { payment, public_key } = await getLinvioPayPayment();
  // console.log("1", payment);
  // console.log("2", payment.id);
  // console.log("3", public_key);
  // if (payment?.id) {
  //   let capture = await captureLinvioPayments(payment?.id);
  //   console.log("capture response", capture);
  // }
}

async function initCreateMethod() {
  // console.log("hit create method");
  // const response = await createLinvioPayMethods();
  // console.log("response", response);
  // console.log("2", public_key);
  // const configuration = {
  //   linvioPayPublicKey: public_key,
  //   paymentId: payment.id,
  //   mode: "test",
  // };
  // const startUterm = () => {
  //   if (!window.uTerm) {
  //     setTimeout(startUterm, 500);
  //     return;
  //   }
  //   window.uTerm("#terminal", configuration);
  // };
  // startUterm();
}
async function initMethods() {
  // console.log("hit methods");
  // const response = await getLinvioPayMethods();
  // console.log("response", response);
  // console.log("2", public_key);
  // const configuration = {
  //   linvioPayPublicKey: public_key,
  //   paymentId: payment.id,
  //   mode: "test",
  // };
  // const startUterm = () => {
  //   if (!window.uTerm) {
  //     setTimeout(startUterm, 500);
  //     return;
  //   }
  //   window.uTerm("#terminal", configuration);
  // };
  // startUterm();
}
async function initPayments() {
  // console.log("hit methods");
  // const response = await captureLinvioPayments();
  // console.log("response", response);
  // console.log("2", public_key);
  // const configuration = {
  //   linvioPayPublicKey: public_key,
  //   paymentId: payment.id,
  //   mode: "test",
  // };
  // const startUterm = () => {
  //   if (!window.uTerm) {
  //     setTimeout(startUterm, 500);
  //     return;
  //   }
  //   window.uTerm("#terminal", configuration);
  // };
  // startUterm();
}

class HomeRequest extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <h1>Linvio test screen</h1>
        <div id="terminal"></div>
        <AppButton onClick={() => initPayInvoice()} text="Test pay invoice" />
        <AppButton
          onClick={() => initCreateMethod()}
          text="Test Create Methods"
        />
        <AppButton onClick={() => initMethods()} text="Test Get Methods" />
        <AppButton onClick={() => initPayments()} text="Test Get Payments" />
      </div>
    );
  }
}

export default withRouter(HomeRequest);
