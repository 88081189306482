import React, { PureComponent } from "react";
import "../../css/style.scss";

import Button from "react-bootstrap/Button";

class AppGhostButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Button
        className="appButton"
        style={{
          marginTop: "50px",
          color: "#D54100",
          backgroundColor: "transparent",
          border: "2px solid",
          borderRadius: 25,
          width: "fit-content",
          fontSize: "20px",
          letterSpacing: "2.5px",
          // marginBottom: "30px",
          paddingRight: "40px",
          paddingLeft: "40px",
          fontWeight: "700",
          fontFamily: "museo-sans",
        }}
        tabIndex={this.props.tabindex}
        type="Submit"
        onClick={this.props.onClick}
        aria-label={this.props.arialabel}
      >
        {this.props.text}
      </Button>
    );
  }
}

export default AppGhostButton;
