import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";

import amenityServicesLogo from "../../assets/logos/amenityServicesLogo.png";
import { AccountDropdown } from "../index";
import backgroundLarge from "../../assets/imgs/waveHorizontal.png";

import { MdOutlineAccountCircle, MdOutlineMenu } from "react-icons/md";

import { Navbar, Nav, Image } from "react-bootstrap";
import MobileNavbar from "./MobileNavbar";
import InvoicesDropdown from "./InvoicesDropdown";
import { GLOBAL_DOMAIN } from "../../actions/types";
import { onASClearFields } from "../../actions";
import axios from "axios";
import ServicesDropdown from "./ServicesDropdown";

const expand = window.innerWidth > 1300;
const spacer = expand ? "50px" : "25px";

class AppNavbar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      account: false,
      invoices: false,
      showMobileNavbar: false,
    };
  }
  onExitPress = () => {
    this.setState({ showMobileNavbar: false });
  };
  async logOut() {
    localStorage.removeItem("token");
    try {
      await axios.get(`${GLOBAL_DOMAIN}/api/auth/logout`, {
        params: {
          token: this.state.token,
          baseDomain: window.location.href,
        },
      });
      // console.log("logoutResponse", logoutResponse);
      localStorage.removeItem("accountId");
      localStorage.removeItem("code");
      localStorage.removeItem("region");
      localStorage.removeItem("contactId");
      localStorage.removeItem("dashboardMessageSeen");
      onASClearFields();
    } catch (err) {
      console.log(err);
    }
  }
  render() {
    return (
      <>
        {/* Desktop Nav */}
        <Navbar
          expand="lg"
          className="mainNavbar"
          style={{
            top: "-20px",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundImage: `url(${backgroundLarge})`,
            backgroundRepeat: "round",
            height: "225px",
          }}
        >
          <Navbar.Brand
            as={Link}
            to="/Dashboard"
            onClick={() => this.setState({ account: false })}
            style={{ margin: "0px 10%" }}
            tabIndex={1}
            aria-label="Navigate to dashboard"
          >
            <Image
              alt="Poolman logo"
              style={{
                height: "75px",
                // marginLeft: "15%",
                // marginRight: "15%",
                marginBottom: "20px",
              }}
              src={amenityServicesLogo}
            ></Image>
          </Navbar.Brand>

          <div className="waveNavbarRightHalf">
            <Navbar.Collapse id="basic-navbar-nav">
              <div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                      backgroundColor: "transparent",
                      marginTop: "30px",
                    }}
                  >
                    <Link
                      to={"/AccountDetails"}
                      style={{ textDecoration: "none" }}
                      onClick={(e) => {
                        e.preventDefault();
                        let account = !this.state.account;
                        this.setState({
                          account,
                          invoices: false,
                          services: false,
                        });
                      }}
                      tabIndex={2}
                      aria-label="Open my account dropdown"
                    >
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                          display: "flex",
                          justifyContent: "flex-end",
                          marginBottom: "25px",
                        }}
                        className="account whiteUnderlineHover"
                      >
                        <MdOutlineAccountCircle
                          size={25}
                          color="white"
                          style={{ marginRight: "10px" }}
                        />

                        <p
                          style={{
                            fontSize: "17px",
                            color: "white",
                            fontFamily: "museo-sans",
                            fontWeight: "700",
                            letterSpacing: "1px",
                          }}
                        >
                          MY ACCOUNT
                        </p>
                      </button>
                    </Link>
                  </div>
                  {/* Desktop Account Toggle */}
                  <div
                    className="account_toggle"
                    style={{
                      display: !this.state.account && "none",
                    }}
                    onClick={() => {
                      let account = true;
                      this.setState({ account });
                    }}
                  >
                    <AccountDropdown
                      path={this.props.location.pathname}
                      onASClearFields={this.props.onASClearFields}
                      handleMouseLeave={() => this.setState({ account: false })}
                    />
                  </div>
                  <div
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                      backgroundColor: "transparent",
                      marginTop: "18px",
                      marginRight: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        fontSize: "30px",
                        fontWeight: "200",
                      }}
                    >
                      |
                    </p>
                  </div>
                  <div
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                      backgroundColor: "transparent",
                      marginTop: "30px",
                    }}
                  >
                    <Link
                      to={"/logout"}
                      style={{ textDecoration: "none" }}
                      onClick={(e) => {
                        this.logOut();
                      }}
                      tabIndex={7}
                      aria-label="Log Out"
                    >
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                          display: "flex",
                          justifyContent: "flex-end",
                          marginBottom: "25px",
                        }}
                        className="logout whiteUnderlineHover"
                        href="/logout"
                      >
                        <p
                          style={{
                            fontSize: "17px",
                            color: "white",
                            fontFamily: "museo-sans",
                            fontWeight: "700",
                            letterSpacing: "1px",
                          }}
                        >
                          LOG OUT
                        </p>
                      </button>
                    </Link>
                  </div>
                </div>
                <Nav className="mr-auto" activeKey="/Dashboard">
                  {/* <Nav.Item eventkey="/Payments">
                    <Nav.Link
                      onClick={() => this.setState({ account: false })}
                      as={Link}
                      to="/Payments"
                      style={{
                        fontSize: "17px",
                        color: "white",
                        textDecorationLine:
                          this.props.location.pathname === "/Payments"
                            ? "underline"
                            : "none",
                        fontFamily: "museo-sans",
                        fontWeight: "700",
                        textDecorationThickness:
                          this.props.location.pathname === "/Payments" && 3,
                      }}
                      className="whiteUnderlineHover"
                    >
                      MY INVOICES
                    </Nav.Link>
                  </Nav.Item> */}
                  <Link
                    to={"/MyInvoices"}
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      let invoices = !this.state.invoices;
                      this.setState({
                        invoices,
                        account: false,
                        services: false,
                      });
                    }}
                    tabIndex={7}
                    aria-label="Open my invoices dropdown"
                  >
                    <div
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      className="account whiteUnderlineHover"
                      // onClick={() => {

                      // }}
                    >
                      <p
                        style={{
                          fontSize: "17px",
                          color: "white",
                          fontFamily: "museo-sans",
                          fontWeight: "700",
                          letterSpacing: "1px",
                          marginBottom: "0px",
                          whiteSpace: "nowrap !important",
                        }}
                        className="navTogleText"
                      >
                        MY INVOICES
                      </p>
                    </div>
                  </Link>
                  <div
                    className="invoice_toggle"
                    style={{
                      display: !this.state.invoices && "none",
                    }}
                    onClick={() => {
                      this.setState({
                        invoices: true,
                        account: false,
                        services: false,
                      });
                    }}
                  >
                    <InvoicesDropdown
                      onASClearFields={this.props.onASClearFields}
                      path={this.props.location.pathname}
                      handleMouseLeave={() =>
                        this.setState({ invoices: false })
                      }
                    />
                  </div>
                  <span style={{ width: spacer }} />
                  <Link
                    to={"/MyServices"}
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      let services = !this.state.services;
                      this.setState({
                        services,
                        account: false,
                        invoices: false,
                      });
                    }}
                    tabIndex={12}
                    aria-label="Open my services dropdown"
                  >
                    <div
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      className="account whiteUnderlineHover"
                    >
                      <p
                        style={{
                          fontSize: "17px",
                          color: "white",
                          fontFamily: "museo-sans",
                          fontWeight: "700",
                          letterSpacing: "1px",
                          marginBottom: "0px",
                        }}
                      >
                        MY SERVICES
                      </p>
                    </div>
                  </Link>
                  <div
                    className="service_toggle"
                    style={{
                      display: !this.state.services && "none",
                    }}
                    onClick={() => {
                      this.setState({
                        services: true,
                        account: false,
                        invoices: false,
                      });
                    }}
                  >
                    <ServicesDropdown
                      onASClearFields={this.props.onASClearFields}
                      path={this.props.location.pathname}
                      handleMouseLeave={() =>
                        this.setState({ services: false })
                      }
                    />
                  </div>
                  <span style={{ width: spacer }} />

                  <Nav.Item eventkey="/Contact">
                    <Nav.Link
                      onClick={() => this.setState({ account: false })}
                      as={Link}
                      to="/Contact"
                      style={{
                        fontSize: "17px",
                        color: "white",
                        textDecorationLine:
                          this.props.location.pathname === "/Contact"
                            ? "underline"
                            : "none",
                        fontFamily: "museo-sans",
                        fontWeight: "700",
                        textDecorationThickness:
                          this.props.location.pathname === "/Contact" && 3,
                        letterSpacing: "1px",
                      }}
                      className="whiteUnderlineHover"
                      tabIndex={16}
                      aria-label="Navigate to contact screen"
                    >
                      CONTACT
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Navbar.Collapse>
          </div>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => this.setState({ account: false })}
            style={{ color: "#1A4EA2" }}
          />
        </Navbar>
        <MobileNavbar
          show={this.state.showMobileNavbar}
          onExitPress={this.onExitPress}
        />
        {/* Mobile Nav */}
        <div
          className="mobileNavbar"
          style={{
            top: "-20px",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundImage: `url(${backgroundLarge})`,
            backgroundRepeat: "round",
            height: "150px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0% 5%",
            }}
          >
            <Link to={"/Dashboard"}>
              <Image
                style={{
                  height: "50px",
                  marginTop: "25px",
                }}
                alt="Poolman logo"
                src={amenityServicesLogo}
                aria-label="Navigate to dashboard"
              ></Image>
            </Link>
            <button
              style={{
                backgroundColor: "#FFE160",
                border: "none",
                borderRadius: "25px",
                padding: "10px",
                marginTop: "25px",
              }}
              aria-label="Open mobile navigation"
              aria-controls="Open mobile navigation"
              onClick={() => this.setState({ showMobileNavbar: true })}
            >
              <MdOutlineMenu size={25} color="#1A4EA2" />
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(AppNavbar);
