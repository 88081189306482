import React, { PureComponent } from "react";

import { connect } from "react-redux";
import AppNavbar from "../CustomComponents/AppNavbarTransparent";
import { MdOutlineArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import DashboardMessage from "../CustomComponents/AppDashboardMessage";
import {
  GLOBAL_DOMAIN,
  GLOBAL_SF_DOMAIN,
  GLOBAL_WEB_DOMAIN,
} from "../../actions/types";
import axios from "axios";
import paymentIcon from "../../assets/svg-icos/ico-card_payment.svg";
import arrowIcon from "../../assets/svg-icos/ico-arrow.svg";
import alertIcon from "../../assets/svg-icos/ico-alert.svg";
import userIcon from "../../assets/svg-icos/ico-user_person.svg";

import { Modal } from "react-bootstrap";
import { IoIosCloseCircleOutline } from "react-icons/io";

import "../../css/style.scss";
import TermsAndPrivacy from "../CustomComponents/TermsAndPrivacy";
import LoadErrorModal from "../CustomComponents/LoadErrorModal";
import AppButton from "../CustomComponents/AppButton";
import AppButtonDesktop from "../CustomComponents/AppButtonDesktop";
import AppGhostButtonDesktop from "../CustomComponents/AppGhostButtonDesktop";
import PrivacyPolicy from "../CustomComponents/PrivacyPolicy";
class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      showModal: false,
      modalMessage: "",
      showLoadErrorModal: false,
      outstandingBalance: 0,
    };
  }
  handleClose = () => {
    localStorage.setItem("dashboardMessageSeen", "true");
    this.setState({ showModal: false });
  };

  componentDidMount = async () => {
    let accountID = localStorage.getItem("accountId");
    let contactID = localStorage.getItem("contactId");
    if (!accountID && !contactID) {
      window.location.href = `${GLOBAL_SF_DOMAIN}/services/oauth2/authorize?client_id=3MVG9hq7jmfCuKfcfEvXMNxD433CfsCPKAYx8ArK6TiGKw1aiwZ6qkalCe7mrAraIjbIxajp2kecEnIMR1U40&redirect_uri=https://${GLOBAL_WEB_DOMAIN}/login&scope=refresh_token%20api%20web&response_type=code&prompt=login`;
    } else {
      try {
        let token = localStorage.getItem("token");
        // CHECK IF WE GET A TOKEN, ERROR IF NO TOKEN
        if (!token || token === "" || token === undefined) {
          this.setState({
            modalMessage:
              "Issue getting auth token, please refresh page. If issue persists please contact support",
            showModal: true,
          });
        }
        let getAccount = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/services/account`,
          {
            params: {
              Id: localStorage.getItem("accountId"),
              token,
              baseDomain: window.location.href,
            },
          }
        );
        let data = getAccount.data.records;
        let getContact = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/services/contact`,
          {
            params: {
              Id: localStorage.getItem("contactId"),
              token,
              baseDomain: window.location.href,
            },
          }
        );
        let contactData = getContact.data.records;
        // console.log("contactData", contactData);
        // GET INVOICE INFO
        let getInvoices = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/payment/unpaidInvoices`,
          {
            params: {
              contactId: localStorage.getItem("contactId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );

        let invoices = getInvoices.data.records;
        // console.log(invoices);
        let balance = 0;
        if (invoices?.length) {
          // console.log(invoices);
          for (let i = 0; i < invoices?.length; i++) {
            // CHECK FOR PENDING PAYMENTS MADE ON INVOICE
            if (invoices[i]?.Invoice_Payments__r?.records?.length) {
              let paymentRecords = invoices[i]?.Invoice_Payments__r?.records;
              for (let j = 0; j < paymentRecords.length; j++) {
                if (paymentRecords[j]?.pymt__Status__c === "Pending") {
                  balance = balance - paymentRecords[j]?.pymt__Amount__c;
                }
              }
            }
            balance = balance + invoices[i]?.Balance_Due__c;
          }
        }
        if (data) {
          this.setState({
            userData: data,
            outstandingBalance: balance,
          });
          this.checkModalMessage(contactData);
        } else {
          this.setState({
            showLoadErrorModal: true,
          });
        }
      } catch (err) {
        console.log("Error:", err);
      }
    }
  };
  checkModalMessage = async (contactData) => {
    // Check if message has been seen
    let smsNum = contactData[0]?.SMS_Mobile_Number_Marketing_Only__c;
    let seen = localStorage.getItem("dashboardMessageSeen");
    if (seen !== "true" && !smsNum) {
      this.setState({
        showModal: true,
      });
    }
  };
  render() {
    return (
      <div
        className="dashboardBackground"
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "round",
          width: "100%",
        }}
      >
        <AppNavbar />
        <div className="dashboardContentDiv">
          {/* PRICE AND PAY NOW SECTION */}
          <div
            className="payNowDiv"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <p
              className="payByText"
              style={{
                color: "white",
                fontWeight: "700",
                fontSize: "17px",
                fontFamily: "museo-sans",
                margin: "0px",
              }}
            >
              {/* PAY BY NOV 28 */} CURRENT BALANCE
            </p>
            <p
              className="totalBalanceDue"
              style={{
                color: "white",
                fontSize: "58px",
                fontFamily: "museo-sans",
                display: "flex",
                alignItems: "center",
                fontWeight: "100",
              }}
            >
              <span style={{ fontSize: "35px", fontWeight: "300" }}>$</span>
              {this?.state?.outstandingBalance > 0
                ? this?.state?.outstandingBalance.toFixed(2)
                : this?.state?.outstandingBalance}
            </p>
            <div className="payNowButtonDiv">
              {this?.state?.outstandingBalance > 0 ? (
                <Link
                  to={"/Payments"}
                  tabIndex={17}
                  aria-label="Navigate to payments screen"
                >
                  <AppButtonDesktop text="PAY NOW" />
                </Link>
              ) : (
                <AppGhostButtonDesktop text="No Payment" tabIndex={17} />
              )}
            </div>
            <Link
              to={"/MyInvoices"}
              style={{
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
                textDecoration: "none",
              }}
              tabIndex={18}
              aria-label="Navigate to my invoices"
            >
              <p
                className="reviewInvoicesButton goldUnderlineHover"
                style={{
                  // textAlign: "center",
                  color: "#FFE160",
                  fontSize: "18px",
                  fontFamily: "museo-sans",
                  fontWeight: "700",
                }}
              >
                Review invoice(s){" "}
                <MdOutlineArrowRight size={25} style={{ marginLeft: "-5px" }} />
              </p>
            </Link>
          </div>
          {/* QUICK LINKS SECTION */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="quickLinksHeader"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                className="quickLinksLine"
                style={{
                  width: "100%",
                  border: ".5px solid",
                  height: "1px",
                  color: "white",
                  margin: "0px 40px",
                }}
              />
              <p
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: "16px",
                  width: "100px",
                  margin: "0px",
                  fontFamily: "museo-sans",
                  fontWeight: "700",
                  whiteSpace: "nowrap",
                }}
              >
                QUICK LINKS
              </p>
              <span
                className="quickLinksLine"
                style={{
                  width: "100%",
                  border: ".5px solid",
                  height: "1px",
                  color: "white",
                  margin: "0px 40px",
                }}
              />
            </div>
            <div
              className="quickLinksDiv"
              style={{
                display: "flex",

                justifyContent: "center",
                margin: "20px 0px",
              }}
            >
              <div className="quickLinksLeftDiv">
                <Link
                  className="goldUnderlineHover"
                  to={"/AddNewPaymentMethod"}
                  style={{
                    textDecoration: "none",
                  }}
                  tabIndex={19}
                  aria-label="Add payment method"
                >
                  <div className="quickLinksItemContainer">
                    <img
                      src={paymentIcon}
                      alt="Add payment method"
                      style={{ height: "25px", marginRight: "10px" }}
                    />
                    <p className="quickLinkItemText">Add payment method</p>
                  </div>
                </Link>
                <Link
                  className="goldUnderlineHover"
                  to={"/RepairRequest"}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    textDecoration: "none",
                  }}
                  tabIndex={20}
                  aria-label="Get a repair estimate"
                >
                  <div className="quickLinksItemContainer">
                    <img
                      src={arrowIcon}
                      alt="Get a repair estimate"
                      style={{ height: "25px", marginRight: "10px" }}
                    />
                    <p className="quickLinkItemText">Get a repair estimate</p>
                  </div>
                </Link>
              </div>
              <div className="quickLinksRightDiv">
                <Link
                  className="goldUnderlineHover"
                  to={`/AccountDetails/${"Notifications"}`}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    textDecoration: "none",
                  }}
                  tabIndex={21}
                  aria-label="Add SMS Notifications"
                >
                  <div className="quickLinksItemContainer">
                    <img
                      src={alertIcon}
                      alt="Add SMS Notifications"
                      style={{ height: "25px", marginRight: "10px" }}
                    />
                    <p className="quickLinkItemText">Add SMS notifications</p>
                  </div>
                </Link>
                <Link
                  className="goldUnderlineHover"
                  to={"/Contact"}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    textDecoration: "none",
                  }}
                  tabIndex={22}
                  aria-label="Contact us"
                >
                  <div className="quickLinksItemContainer">
                    <img
                      src={userIcon}
                      alt="Contact us"
                      style={{ height: "25px", marginRight: "10px" }}
                    />
                    <p className="quickLinkItemText">Contact us</p>
                  </div>
                </Link>
                {/* <Link
                  className="goldUnderlineHover"
                  to={"/Contact"}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    textDecoration: "none",
                  }}
                >
                  <div className="quickLinksItemContainer">
                    <img
                      src={dialogueIcon}
                      alt="Give us feedback"
                      style={{ height: "25px", marginRight: "10px" }}
                    />
                    <p className="quickLinkItemText">Give us feedback</p>
                  </div>
                </Link> */}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DashboardMessage />
          </div>
        </div>
        <Modal
          show={this.state.showModal}
          onHide={this.handleClose}
          aria-labelledby="Dashboard alert modal"
          centered
          contentClassName="custom-modal-dashboard"
          style={{ borderRadius: "25px" }}
        >
          <Modal.Body>
            <>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  aria-label="Close Modal"
                  style={{ backgroundColor: "transparent", border: "none" }}
                  onClick={() => this.handleClose()}
                >
                  <IoIosCloseCircleOutline
                    size={50}
                    style={{ marginBottom: "25px" }}
                    color="#d44203"
                  />
                </button>
              </div>
              <p
                style={{
                  fontSize: "25px",
                  fontFamily: "Oswald",
                  color: "#d44203",
                  fontWeight: "300",
                  lineHeight: "30px",
                  marginBottom: "50px",
                }}
              >
                {/* {this.state.modalMessage} */}
                Get important notifications about your account balance via SMS,
                add a mobile number now
              </p>
              <Link
                className="blueUnderlineHover"
                to={`/AccountDetails/${"Notifications"}`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  textDecoration: "none",
                  // textDecorationColor: "#1A4EA2",
                  justifyContent: "center",
                }}
                // tabIndex={21}
                aria-label="Add SMS Notifications"
              >
                <AppButton text="Add SMS" />
              </Link>
            </>
          </Modal.Body>
        </Modal>
        {this.state.showLoadErrorModal && <LoadErrorModal />}

        <div
          className="termsAndPrivacyContainer"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <PrivacyPolicy color="white" tabindex={24} />
          <p
            style={{
              color: "white",
              fontWeight: "500",
              marginRight: "10px",
              fontSize: "13px",
            }}
          >
            |
          </p>
          <TermsAndPrivacy color="white" tabindex={25} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(Dashboard);
