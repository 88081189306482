/** @format */

import React, { PureComponent } from "react";
import "../../css/style.scss";
import { onASClearFields } from "../../reducers/AccountSetupReducer";
import Nav from "react-bootstrap/Nav";

class AccountDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: {},
      showChangePassword: false,
      changePasswordSuccess: false,
    };
  }
  handleMouseLeaveFunc = () => {
    this.props.handleMouseLeave();
  };

  render() {
    return (
      <div onMouseLeave={() => this.handleMouseLeaveFunc()}>
        <Nav className="accountDropdownContainer">
          <Nav.Item
            style={{ borderBottomWidth: "1px", borderBottomColor: "white" }}
          >
            <Nav.Link
              className="navDropdownItem"
              href="/AccountDetails"
              eventKey="AccountDetails"
              style={{}}
              tabIndex={3}
              aria-label="Navigate to account details"
            >
              ACCOUNT DETAILS
            </Nav.Link>
          </Nav.Item>
          <span className="accountDropdownSpacer" />
          <Nav.Item>
            <Nav.Link
              className="navDropdownItem"
              eventKey="PayBill"
              href="/EditAccountDetails"
              tabIndex={4}
              aria-label="NAvigate to edit account info"
            >
              EDIT ACCOUNT INFO
            </Nav.Link>
          </Nav.Item>
          <span className="accountDropdownSpacer" />
          <Nav.Item>
            <Nav.Link
              className="navDropdownItem"
              eventKey="AddSmsContact"
              href={`/AccountDetails/${"AddSMS"}`}
              tabIndex={5}
              aria-label="Navigate to add SMS contact"
            >
              ADD SMS CONTACT
            </Nav.Link>
          </Nav.Item>
          <span className="accountDropdownSpacer" />
          <Nav.Item>
            <Nav.Link
              className="navDropdownItem"
              href="/login"
              eventKey="LogOut"
              onClick={() => {
                localStorage.removeItem("contactId");
                localStorage.removeItem("accountId");
                localStorage.removeItem("dashboardMessageSeen");
                localStorage.removeItem("token");
                onASClearFields();
              }}
              tabIndex={6}
              aria-label="Log out"
            >
              LOG OUT
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    );
  }
}

export default AccountDropdown;
