import React, { PureComponent } from "react";

import { connect } from "react-redux";
import AppNavbar from "../CustomComponents/AppNavbarWave";
import { RxCrossCircled, RxCheckCircled } from "react-icons/rx";
import TermsAndPrivacy from "../CustomComponents/TermsAndPrivacy";
import { Link } from "react-router-dom";
import { GLOBAL_SF_DOMAIN, GLOBAL_WEB_DOMAIN } from "../../actions/types";
import PrivacyPolicy from "../CustomComponents/PrivacyPolicy";
class PaymentConfirmation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      success: null,
      services: [],
      repairs: [],
      total: 0,
      method: "",
    };
  }
  componentDidMount = async () => {
    let accountID = localStorage.getItem("accountId");
    let contactID = localStorage.getItem("contactId");
    if (!accountID && !contactID) {
      window.location.href = `${GLOBAL_SF_DOMAIN}/services/oauth2/authorize?client_id=3MVG9hq7jmfCuKfcfEvXMNxD433CfsCPKAYx8ArK6TiGKw1aiwZ6qkalCe7mrAraIjbIxajp2kecEnIMR1U40&redirect_uri=https://${GLOBAL_WEB_DOMAIN}/login&scope=refresh_token%20api%20web&response_type=code&prompt=login`;
    } else {
      let responseSuccess = this.props.location.state.success;
      let responseAmount = this.props.location.state.amount;
      let responseServices = this.props.location.state.services;
      let responseRepairs = this.props.location.state.repairs;
      this.setState({
        success: responseSuccess,
        total: responseAmount,
        services: responseServices,
        repairs: responseRepairs,
      });
    }
  };
  renderServices = () => {
    return this.state.services.map((item, i) => {
      return (
        <p
          className="paymentConfirmationServiceItem"
          style={{
            color: this.state.success === false ? "#727272" : "#1A4EA2",
          }}
          key={item.name}
        >
          {item.name}
        </p>
      );
    });
  };
  renderRepairs = () => {
    return this.state.repairs.map((item, i) => {
      return (
        <p
          className="paymentConfirmationServiceItem"
          style={{
            color: this.state.success === false ? "#727272" : "#1A4EA2",
          }}
          key={item.name}
        >
          {item.name}
        </p>
      );
    });
  };
  render() {
    return (
      <>
        <AppNavbar />
        <div className="contentContainer">
          <div className="iconAndHeaderContainer">
            {this.state.success === true ? (
              <RxCheckCircled
                size={55}
                color="#1A4EA2"
                style={{ marginRight: "10px" }}
              />
            ) : (
              <RxCrossCircled
                size={55}
                color="#D54100"
                style={{ marginRight: "10px" }}
              />
            )}
            <h1
              className="app-Header-Text"
              style={{
                color: this.state.success === true ? "#1A4EA2" : "#D54100",
                marginTop: 10,
              }}
            >
              {this.state.success === true
                ? "Thank you for your payment!"
                : "There was an issue processing your payment!"}
            </h1>
            <p className="app-paragraph-text" style={{ marginTop: 15 }}>
              {this.state.success === true
                ? "We’re thrilled you entrust us with your pool, and your business."
                : "We were unable to process your payment for the following invoice, please try again later or contact us at 1-800-990-7440"}
            </p>
          </div>
          <div className="paymentConfirmationServicesContainer">
            {this.state?.services?.length ? this.renderServices() : ""}
            {this.state?.repairs?.length ? this.renderRepairs() : ""}
            <div className="paymentConfirmationTotalContainer">
              {this.state.success && (
                <>
                  <div
                    className="paymentConfirmationTotalText"
                    style={{
                      color:
                        this.state.success === true ? "#1A4EA2" : "#727272",
                      justifySelf: "flex-end",
                    }}
                  >
                    <p style={{ marginBottom: "0px", marginLeft: "100px" }}>
                      TOTAL PAID:
                    </p>
                  </div>
                  <p
                    className="paymentConfirmationTotalNum"
                    style={{
                      color:
                        this.state.success === true ? "#1A4EA2" : "#727272",
                      marginBottom: "0px",
                    }}
                  >
                    {this.state.total}
                  </p>
                </>
              )}
            </div>
          </div>
          <Link
            to={"/Dashboard"}
            className="returnHomeLinkPaymentConfirmation"
            style={{
              color: "#D54100",
            }}
            tabIndex={17}
            air-label="Return to dashboard"
          >
            RETURN TO DASHBOARD
          </Link>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <PrivacyPolicy color="black" tabindex={21} />
            <p
              style={{
                color: "black",
                fontWeight: "500",
                marginRight: "10px",
                fontSize: "13px",
              }}
            >
              |
            </p>
            <TermsAndPrivacy color="black" tabindex={22} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(PaymentConfirmation);
