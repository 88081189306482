import React, { PureComponent } from "react";
import "../../css/style.scss";

import { GoTriangleRight, GoTriangleDown } from "react-icons/go";
import AppSubHeaderText from "./AppSubHeader";

class AppServicesAccordian extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showMoreThanFive: false,
      selectedDescription: "",
    };
  }
  setSelectedService = (id) => {
    this.props.setSelectedService(id);
  };
  setSelectedRepair = (id) => {
    this.props.setSelectedRepair(id);
  };
  setSelectedDescription = (id) => {
    this.props.setSelectedDescription(id);
  };

  getMonth = (number) => {
    switch (number) {
      case "01":
        return "January";
      case "02":
        return "February";
      case "03":
        return "March";
      case "04":
        return "April";
      case "05":
        return "May";
      case "06":
        return "June";
      case "07":
        return "July";
      case "08":
        return "August";
      case "09":
        return "September";
      case "10":
        return "October";
      case "11":
        return "November";
      case "12":
        return "December";
      default:
        return "";
    }
  };

  renderItem = (item, i) => {
    let month = "";
    let day = "";
    if (item?.Suggested_Maintenance_Date__c) {
      let string = item?.Suggested_Maintenance_Date__c.split("-");
      month = this.getMonth(string[1]);
      day = string[2];
    }
    if (item?.WT_Type__c === "Repair") {
      return;
    }
    return (
      <div key={i}>
        <button
          aria-label="Show item description"
          style={{
            border: "none",
            backgroundColor: "white",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "60px",
            borderTop: "1px solid #E9E9E9",
          }}
          tabIndex={17}
          onClick={() => {
            if (this.state.selectedDescription === item?.Id) {
              this.setState({ selectedDescription: "" });
            } else {
              this.setState({ selectedDescription: item?.Id });
            }
          }}
        >
          <p
            className="appAccordianName"
            style={{
              color: "#D54100",
              marginLeft: 0,
            }}
          >
            {item?.WT_Type__c === "Maintenance"
              ? "Pool Service"
              : item?.WT_Type__c}{" "}
            {item?.WT_Type__c !== "Repair" && `on ${month} ${day}`}
          </p>
          {this.state.selectedDescription === item?.Id ? (
            <GoTriangleDown size={28} color="#D54100" />
          ) : (
            <GoTriangleRight size={30} color="#D54100" />
          )}
        </button>
        {this.state.selectedDescription === item.Id && (
          <div>
            {item?.Actual_Start_Tech_Local_Time__c && (
              <h3
                style={{
                  color: "#D54100",
                  fontFamily: "Oswald",
                  fontSize: "20px",
                  letterSpacing: 0,
                  marginBottom: 30,
                }}
              >
                Arrived at {item?.Actual_Start_Tech_Local_Time__c}
              </h3>
            )}
            <AppSubHeaderText text="WATER CONDITIONS:" />
            <div className="serviceReadingsChart">
              <div className="serviceReadingsChartLeftContainer">
                <div
                  className="serviceReadingsItem"
                  style={{ backgroundColor: "#F4F4F4" }}
                >
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontSize: "16px",
                    }}
                  >
                    Chlorine PPM
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {item?.WorkOrderLineItems?.records[0]?.Chlorine_Values__c
                      ? item?.WorkOrderLineItems?.records[0]?.Chlorine_Values__c
                      : "N/A"}
                  </p>
                </div>
                <div className="serviceReadingsItem">
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontSize: "16px",
                    }}
                  >
                    PH
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {item?.WorkOrderLineItems?.records[0]?.PH__c
                      ? item?.WorkOrderLineItems?.records[0]?.PH__c
                      : "N/A"}
                  </p>
                </div>
                <div
                  className="serviceReadingsItem"
                  style={{ backgroundColor: "#F4F4F4" }}
                >
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontSize: "16px",
                    }}
                  >
                    Alkalinity
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {item?.WorkOrderLineItems?.records[0]?.ALK__c
                      ? item?.WorkOrderLineItems?.records[0]?.ALK__c
                      : "N/A"}
                  </p>
                </div>
                <div className="serviceReadingsItem">
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontSize: "16px",
                    }}
                  >
                    Filter PSI
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {item?.WorkOrderLineItems?.records[0]?.Filter_PSI__c
                      ? item?.WorkOrderLineItems?.records[0]?.Filter_PSI__c
                      : "N/A"}
                  </p>
                </div>
              </div>
              <div className="serviceReadingsChartLeftContainer">
                <div
                  className="serviceReadingsItem"
                  style={{ backgroundColor: "#F4F4F4" }}
                >
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontSize: "16px",
                    }}
                  >
                    Stabilizer
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {item?.WorkOrderLineItems?.records[0]?.CA_Reading__c
                      ? item?.WorkOrderLineItems?.records[0]?.CA_Reading__c
                      : "N/A"}
                  </p>
                </div>
                <div className="serviceReadingsItem">
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontSize: "16px",
                    }}
                  >
                    Salt level
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {item?.WorkOrderLineItems?.records[0]?.Salt_Level__c
                      ? item?.WorkOrderLineItems?.records[0]?.Salt_Level__c
                      : "N/A"}
                  </p>
                </div>
                <div
                  className="serviceReadingsItem"
                  style={{ backgroundColor: "#F4F4F4" }}
                >
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontSize: "16px",
                    }}
                  >
                    Phosphate level
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {item?.WorkOrderLineItems?.records[0]?.Phosphate_level__c
                      ? item?.WorkOrderLineItems?.records[0]?.Phosphate_level__c
                      : "N/A"}
                  </p>
                </div>
                <div className="serviceReadingsItem">
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontSize: "16px",
                    }}
                  >
                    Water level
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {item?.WorkOrderLineItems?.records[0]?.Water_Level__c
                      ? item?.WorkOrderLineItems?.records[0]?.Water_Level__c
                      : "N/A"}
                  </p>
                </div>
              </div>
            </div>
            <AppSubHeaderText text="SERVICES PERFORMED:" />
            <div className="serviceReadingsChart">
              <div className="serviceReadingsChartLeftContainer">
                {/* <div className="servicePerformedItem">
                  <p className="servicePerformedCheck">
                    {item?.WorkOrderLineItems?.records[0]?.Chlorine_Values__c
                      ? "✓"
                      : "x"}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontSize: "16px",
                    }}
                  >
                    ! Water checked/balanced
                  </p>
                </div> */}
                <div className="servicePerformedItem">
                  <p className="servicePerformedCheck">
                    {item?.WorkOrderLineItems?.records[0]?.Brushed__c
                      ? "✓"
                      : "x"}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontSize: "16px",
                    }}
                  >
                    Brush perimeter tile
                  </p>
                </div>
                <div className="servicePerformedItem">
                  <p className="servicePerformedCheck">
                    {item?.WorkOrderLineItems?.records[0]?.Skimmed__c
                      ? "✓"
                      : "x"}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontSize: "16px",
                    }}
                  >
                    Skim debris
                  </p>
                </div>
                <div className="servicePerformedItem">
                  <p className="servicePerformedCheck">
                    {item?.WorkOrderLineItems?.records[0]?.Vacuum__c
                      ? "✓"
                      : "x"}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontSize: "16px",
                    }}
                  >
                    Vacuumed
                  </p>
                </div>
              </div>
              <div className="serviceReadingsChartLeftContainer">
                <div className="servicePerformedItem">
                  <p className="servicePerformedCheck">
                    {item?.WorkOrderLineItems?.records[0]
                      ?.Skimmer_Basket_Emptied__c
                      ? "✓"
                      : "x"}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontSize: "16px",
                    }}
                  >
                    Skimmer basket emptied
                  </p>
                </div>
                <div className="servicePerformedItem">
                  <p className="servicePerformedCheck">
                    {item?.WorkOrderLineItems?.records[0]
                      ?.Pump_Basket_Emptied__c
                      ? "✓"
                      : "x"}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontSize: "16px",
                    }}
                  >
                    Pump basket emptied
                  </p>
                </div>
                {/* <div className="servicePerformedItem">
                  <p className="servicePerformedCheck">
                    {item?.WorkOrderLineItems?.records[0]?.Chlorine_Values__c
                      ? "✓"
                      : "x"}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontSize: "16px",
                    }}
                  >
                    !Equipment inspection
                  </p>
                </div> */}
                <div className="servicePerformedItem">
                  <p className="servicePerformedCheck">
                    {item?.WorkOrderLineItems?.records[0]
                      ?.Backwashed_Cleaned_Filter__c
                      ? "✓"
                      : "x"}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "museo-sans",
                      fontSize: "16px",
                    }}
                  >
                    Sand/DE monthly backwash
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  render() {
    return (
      <div>
        {this.props.upcomingServices.map((item, i) => {
          if (!this.props.showMoreThanFive && i < 5) {
            return this.renderItem(item, i);
          } else if (this.props.showMoreThanFive) {
            return this.renderItem(item, i);
          } else {
            return "";
          }
        })}
        {!this.props.upcomingServices.length && (
          <p className="contactSubHeader" style={{ marginTop: 50 }}>
            No Services Are Associated to your Account
          </p>
        )}
      </div>
    );
  }
}

export default AppServicesAccordian;
