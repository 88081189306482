import React, { PureComponent } from "react";

import { connect } from "react-redux";
import AppNavbarWave from "../CustomComponents/AppNavbarWave";
import axios from "axios";
import { GLOBAL_DOMAIN } from "../../actions/types";
import LoadErrorModal from "../CustomComponents/LoadErrorModal";
import ServicesDayDial from "../CustomComponents/ServicesDayDial";
import Spinner from "react-bootstrap/Spinner";
import AppServicesAccordian from "../CustomComponents/AppServicesAccordion";
import AppGhostButton from "../CustomComponents/AppGhostButton";
import TermsAndPrivacy from "../CustomComponents/TermsAndPrivacy";
import PrivacyPolicy from "../CustomComponents/PrivacyPolicy";

class MyServices extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoadErrorModal: false,
      recurringDay: "",
      servicesData: [],
      servicesDialDay: "",
      showMoreThanFive: false,
      spinner: false,
    };
  }
  componentDidMount = async () => {
    try {
      this.setState({ spinner: true });
      // GET ALL SERVICES INFO
      let getAllServices = await axios.get(
        `${GLOBAL_DOMAIN}/api/new/services/getAllServices`,
        {
          params: {
            token: localStorage.getItem("token"),
            contactId: localStorage.getItem("contactId"),
            baseDomain: window.location.href,
          },
        }
      );
      let allServicesData = getAllServices?.data?.records;

      if (allServicesData) {
        let filteredServicesData = allServicesData.filter(
          (item) => item?.WorkOrderLineItems?.records
        );
        allServicesData = filteredServicesData;
      }
      // console.log("All Services", allServicesData);
      // CHECK FOR RECURRING SERVICE DAY
      if (allServicesData?.length) {
        let dayOfWeek = "";
        if (
          allServicesData[0]?.Day_of_Week_Suggested_Maint_Date__c &&
          allServicesData[0]?.Day_of_Week_Suggested_Maint_Date__c !== "Error"
        ) {
          dayOfWeek = allServicesData[0]?.Day_of_Week_Suggested_Maint_Date__c;
        } else if (
          allServicesData[1]?.Day_of_Week_Suggested_Maint_Date__c &&
          allServicesData[1]?.Day_of_Week_Suggested_Maint_Date__c !== "Error"
        ) {
          dayOfWeek = allServicesData[1]?.Day_of_Week_Suggested_Maint_Date__c;
        } else if (
          allServicesData[2]?.Day_of_Week_Suggested_Maint_Date__c &&
          allServicesData[2]?.Day_of_Week_Suggested_Maint_Date__c !== "Error"
        ) {
          dayOfWeek = allServicesData[2]?.Day_of_Week_Suggested_Maint_Date__c;
        }
        this.setState({
          servicesData: allServicesData,
          servicesDialDay: dayOfWeek,
          spinner: false,
        });
      } else if (getAllServices?.data?.success === false) {
        this.setState({ spinner: false, showLoadErrorModal: true });
      } else {
        this.setState({ spinner: false });
      }
    } catch (err) {
      console.log(err);
    }
  };
  renderServices = () => {};
  render() {
    return (
      <>
        <AppNavbarWave />
        <div className="myServicesFlexContainer">
          <div className="myServicesContentContainer">
            <h1
              className="myAccountHeader"
              style={{
                fontSize: "42px",
                color: "#1A4EA2",
                fontFamily: "Oswald",
                fontWeight: "300",
              }}
            >
              My Services
            </h1>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "300",
              }}
            >
              Thanks for being a loyal client! You recurring scheduled service
              day(s) are:
            </p>
            <ServicesDayDial day={this.state.servicesDialDay} />
            <p className="contactSubHeader" style={{ marginTop: 50 }}>
              RECORD OF SERVICE
            </p>

            {this.state.spinner ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 100,
                  marginBottom: 100,
                }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <AppServicesAccordian
                  upcomingServices={this.state.servicesData}
                  showMoreThanFive={this.state.showMoreThanFive}
                />
                <div
                  style={{
                    display:
                      this.state.servicesData.length > 5 &&
                      !this.state.showMoreThanFive
                        ? "flex"
                        : "none",
                    justifyContent: "center",
                    marginTop: 10,
                    borderTop: "#E9E9E9 5px solid",
                  }}
                >
                  <AppGhostButton
                    tabindex={20}
                    text={"LOAD MORE"}
                    onClick={() => {
                      if (this.state.showMoreThanFive) {
                        this.setState({ showMoreThanFive: false });
                      } else {
                        this.setState({ showMoreThanFive: true });
                      }
                    }}
                    arialabel="Load/Hid additional services"
                  />
                </div>
              </>
            )}
          </div>
          {/* <div className="contactRightContainer">
            <AppCardButton
              text="Upgrade or Change Service"
              icon={arrowIcon}
              arialabel="Upgrade or change service"
              iconAlt="Upgrade or change service"
              navigateTo="/MyServices"
              tabindex={19}
            />
            <AppCardButton
              text="Notification Preferences"
              icon={alertIcon}
              arialabel="Notification preferences"
              iconAlt="Notification preferences logo"
              navigateTo={`/AccountDetails/Notifications`}
              tabindex={20}
            />
            <AppCardButton
              text="Request Services"
              icon={serviceIcon}
              arialabel="Request Services"
              iconAlt="Request services icon"
              navigateTo="/Contact"
              tabindex={21}
            />
          </div> */}
        </div>
        {/* <div
          className="termsAndPrivacyContainerPayments"
          style={{ marginTop: 100 }}
        >
          <TermsAndPrivacy color="#727272" tabindex={21} />
        </div> */}
        <div
          className="termsAndPrivacyContainerPayments"
          style={{ display: "flex", flexDirection: "row", marginTop: 100 }}
        >
          <PrivacyPolicy color="black" tabindex={21} />
          <p
            style={{
              color: "black",
              fontWeight: "500",
              marginRight: "10px",
              fontSize: "13px",
            }}
          >
            |
          </p>
          <TermsAndPrivacy color="black" tabindex={22} />
        </div>
        {this.state.showLoadErrorModal && <LoadErrorModal />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(MyServices);
