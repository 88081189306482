import React, { PureComponent } from "react";

import { Link } from "react-router-dom";
import "../../css/style.scss";

class TermsAndPrivacy extends PureComponent {
  render() {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <Link
          to={"/TermsAndConditions"}
          style={{
            color: this.props.color,
            fontSize: "14px",
            textDecoration: "none",
            marginRight: "10px",
            textDecorationLine: "underline",
            fontWeight: "300",
            fontFamily: "museo-sans",
          }}
          tabIndex={this.props.tabindex}
          aria-label="Terms and Conditions"
        >
          Terms of Use
        </Link>
      </div>
    );
  }
}

export default TermsAndPrivacy;
