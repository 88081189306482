import React, { PureComponent } from "react";
import { MdOutlineClose } from "react-icons/md";
import { onASClearFields } from "../../reducers/AccountSetupReducer";

import { Link } from "react-router-dom";
import axios from "axios";

import "../../css/style.scss";
import { GLOBAL_DOMAIN } from "../../actions/types";

class MobileNavbar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategory: "",
      userInfo: {},
      showChangePassword: false,
      changePasswordSuccess: false,
    };
  }

  changeSelectedCategory = (category) => {
    if (this.state.selectedCategory === category) {
      this.setState({ selectedCategory: "" });
    } else {
      this.setState({ selectedCategory: category });
    }
  };

  async logOut() {
    localStorage.removeItem("token");
    try {
      await axios.get(`${GLOBAL_DOMAIN}/api/auth/logout`, {
        params: {
          token: this.state.token,
          baseDomain: window.location.href,
        },
      });
      // console.log("logoutResponse", logoutResponse);
      localStorage.removeItem("accountId");
      localStorage.removeItem("code");
      localStorage.removeItem("region");
      localStorage.removeItem("contactId");
      localStorage.removeItem("dashboardMessageSeen");
      onASClearFields();
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <div
        className="mobileNavbar"
        style={{
          background: "linear-gradient(#007AC8, #002D81)",
          display: this.props.show ? "block" : "none",
          position: "fixed",
          height: "100vh",
          width: "100vw",
          zIndex: 1,
        }}
      >
        {/* Exit Button Div */}
        <div
          style={{
            justifyContent: "flex-end",
            display: "flex",
            margin: "0% 5%",
          }}
        >
          <button
            style={{
              backgroundColor: "#FFE160",
              border: "none",
              borderRadius: "25px",
              padding: "10px",
              marginTop: "35px",
            }}
            aria-label="Close mobile navigation"
            aria-controls="Close mobile navigation"
            onClick={() => this.props.onExitPress()}
          >
            <MdOutlineClose size={25} color="#1A4EA2" />
          </button>
        </div>
        {/* All Items Div */}
        <div
          style={{
            marginLeft: "65px",
            borderWidth: "1px",
            zIndex: 2,
            borderBottomWidth: "1px",
            borderBottomColor: "white",
          }}
        >
          <div>
            <Link to={"/login"} aria-label="Log Out">
              <button
                style={{
                  fontSize: "20px",
                  color: "white",
                  fontFamily: "museo-sans",
                  fontWeight: "700",
                  letterSpacing: "1.2px",
                  textDecoration: "none",
                  backgroundColor: "transparent",
                  border: "none",
                  padding: "0px",
                }}
                onClick={() => {
                  this.logOut();
                }}
                aria-label="Log Out"
              >
                LOG OUT
              </button>
            </Link>
          </div>
          {/* MY ACCOUNT CATEGORY */}
          <div
            style={{
              marginTop: "40px",
              marginLeft:
                this.state.selectedCategory === "MY ACCOUNT" && "-25px",
              borderLeft:
                this.state.selectedCategory === "MY ACCOUNT" &&
                "3px solid #FFE160",
            }}
          >
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                marginLeft:
                  this.state.selectedCategory === "MY ACCOUNT" && "22px",
              }}
              aria-label="Open my account dropdown"
              aria-controls="Open my account dropdown"
              onClick={() => this.changeSelectedCategory("MY ACCOUNT")}
            >
              <h3
                style={{
                  fontSize: "20px",
                  color: "white",
                  fontFamily: "museo-sans",
                  fontWeight: "700",
                  letterSpacing: "1.2px",
                }}
              >
                MY ACCOUNT
              </h3>
            </button>
            <div
              style={{
                display:
                  this.state.selectedCategory === "MY ACCOUNT"
                    ? "flex"
                    : "none",
                flexDirection: "column",
              }}
            >
              <Link
                to={"/AccountDetails"}
                style={{
                  color: "white",
                  fontSize: "18px",
                  textDecoration: "none",
                  marginLeft:
                    this.state.selectedCategory === "MY ACCOUNT"
                      ? "22px"
                      : "10px",
                  marginTop: "25px",
                  fontFamily: "museo-sans",
                  fontWeight: "500",
                }}
                aria-label="Navigate to account details"
              >
                Account details
              </Link>
              <Link
                to={"/EditAccountDetails"}
                style={{
                  color: "white",
                  fontSize: "18px",
                  textDecoration: "none",
                  marginLeft:
                    this.state.selectedCategory === "MY ACCOUNT"
                      ? "22px"
                      : "10px",
                  marginTop: "25px",
                  fontFamily: "museo-sans",
                  fontWeight: "500",
                }}
                aria-label="Navigate to edit account info"
              >
                Edit account info
              </Link>
              <Link
                to={`/AccountDetails/AddSMS`}
                style={{
                  color: "white",
                  fontSize: "18px",
                  textDecoration: "none",
                  marginLeft:
                    this.state.selectedCategory === "MY ACCOUNT"
                      ? "22px"
                      : "10px",
                  marginTop: "25px",
                  fontFamily: "museo-sans",
                  fontWeight: "500",
                }}
                aria-label="Navigate to add SMS contact"
              >
                Add SMS Contact
              </Link>
            </div>
          </div>
          {/* MY INVOICES CATEGORY */}
          <div
            style={{
              marginTop: "40px",
              marginLeft:
                this.state.selectedCategory === "MY INVOICES" && "-25px",
              borderLeft:
                this.state.selectedCategory === "MY INVOICES" &&
                "3px solid #FFE160",
            }}
          >
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                marginLeft:
                  this.state.selectedCategory === "MY INVOICES" && "22px",
              }}
              aria-label="Open my invoices dropdown"
              aria-controls="Open my invoices dropdown"
              onClick={() => this.changeSelectedCategory("MY INVOICES")}
            >
              <h3
                style={{
                  fontSize: "20px",
                  color: "white",
                  fontFamily: "museo-sans",
                  fontWeight: "700",
                  letterSpacing: "1.2px",
                }}
              >
                MY INVOICES
              </h3>
            </button>
            <div
              style={{
                display:
                  this.state.selectedCategory === "MY INVOICES"
                    ? "flex"
                    : "none",
                flexDirection: "column",
              }}
            >
              <Link
                to={"/Payments"}
                style={{
                  color: "white",
                  fontSize: "18px",
                  textDecoration: "none",
                  marginLeft:
                    this.state.selectedCategory === "MY INVOICES"
                      ? "22px"
                      : "10px",
                  marginTop: "25px",
                  fontFamily: "museo-sans",
                  fontWeight: "500",
                }}
                aria-label="Navigate to payments screen"
              >
                View Invoices
              </Link>
              <Link
                to={"/MyInvoices"}
                style={{
                  color: "white",
                  fontSize: "18px",
                  textDecoration: "none",
                  marginLeft:
                    this.state.selectedCategory === "MY INVOICES"
                      ? "22px"
                      : "10px",
                  marginTop: "25px",
                  fontFamily: "museo-sans",
                  fontWeight: "500",
                }}
                aria-label="Navigate to my invoices"
              >
                View previous payments
              </Link>
              <Link
                to={"/AddNewPaymentMethod"}
                style={{
                  color: "white",
                  fontSize: "18px",
                  textDecoration: "none",
                  marginLeft:
                    this.state.selectedCategory === "MY INVOICES"
                      ? "22px"
                      : "10px",
                  marginTop: "25px",
                  fontFamily: "museo-sans",
                  fontWeight: "500",
                }}
                aria-label="Navigate to add new payment method"
              >
                Add new payment method
              </Link>
              <Link
                to={`/AccountDetails/${"AutoPay"}`}
                style={{
                  color: "white",
                  fontSize: "18px",
                  textDecoration: "none",
                  marginLeft:
                    this.state.selectedCategory === "MY INVOICES"
                      ? "22px"
                      : "10px",
                  marginTop: "25px",
                  fontFamily: "museo-sans",
                  fontWeight: "500",
                }}
                aria-label="Navigate to set auto-pay"
              >
                Set auto-pay
              </Link>
            </div>
          </div>
          {/* MY SERVICES CATEGORY */}
          <div
            style={{
              marginTop: "40px",
              marginLeft:
                this.state.selectedCategory === "MY SERVICES" && "-25px",
              borderLeft:
                this.state.selectedCategory === "MY SERVICES" &&
                "3px solid #FFE160",
            }}
          >
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                marginLeft:
                  this.state.selectedCategory === "MY SERVICES" && "22px",
              }}
              aria-label="Open my services dropdown"
              aria-controls="Open my services dropdown"
              onClick={() => this.changeSelectedCategory("MY SERVICES")}
            >
              <h3
                style={{
                  fontSize: "20px",
                  color: "white",
                  fontFamily: "museo-sans",
                  fontWeight: "700",
                  letterSpacing: "1.2px",
                }}
              >
                MY SERVICES
              </h3>
            </button>
            <div
              style={{
                display:
                  this.state.selectedCategory === "MY SERVICES"
                    ? "flex"
                    : "none",
                flexDirection: "column",
              }}
            >
              <Link
                to={"/MyServices"}
                style={{
                  color: "white",
                  fontSize: "18px",
                  textDecoration: "none",
                  marginLeft:
                    this.state.selectedCategory === "MY SERVICES"
                      ? "22px"
                      : "10px",
                  marginTop: "25px",
                  fontFamily: "museo-sans",
                  fontWeight: "500",
                }}
                aria-label="Navigate to my services"
              >
                View my service visits
              </Link>
              {/* <Link
                to={"/ChangeService"}
                style={{
                  color: "white",
                  fontSize: "18px",
                  textDecoration: "none",
                  marginLeft:
                    this.state.selectedCategory === "MY SERVICES"
                      ? "22px"
                      : "10px",
                  marginTop: "25px",
                  fontFamily: "museo-sans",
                  fontWeight: "500",
                }}
                aria-label="Navigate to change service"
              >
                Make changes to service
              </Link> */}
              <Link
                to={"/RepairRequest"}
                style={{
                  color: "white",
                  fontSize: "18px",
                  textDecoration: "none",
                  marginLeft:
                    this.state.selectedCategory === "MY SERVICES"
                      ? "22px"
                      : "10px",
                  marginTop: "25px",
                  fontFamily: "museo-sans",
                  fontWeight: "500",
                }}
                aria-label="Navigate to get repair estimate"
              >
                Get a repair estimate
              </Link>
            </div>
          </div>

          <div style={{ marginTop: "40px" }}>
            <Link
              to={"/Contact"}
              style={{
                fontSize: "20px",
                color: "white",
                fontFamily: "museo-sans",
                fontWeight: "700",
                letterSpacing: "1.2px",
                textDecoration: "none",
              }}
              aria-label="Navigate to contact screen"
            >
              CONTACT
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileNavbar;
