import React, { PureComponent } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TermsAndPrivacy from "../CustomComponents/TermsAndPrivacy";
import AppButton from "../CustomComponents/AppButton";
import LogoutNavbar from "../CustomComponents/LogoutNavbar";
import PrivacyPolicy from "../CustomComponents/PrivacyPolicy";

class LogOut extends PureComponent {
  render() {
    return (
      <>
        <div style={{ height: "100%" }}>
          <LogoutNavbar />
          <div className="routeNotFoundContainer">
            <div>
              <h1 className="logoutTitle">Logged Out</h1>
              <Link to={"/login"} tabIndex={17} aria-label="Return to login">
                <AppButton text="Return to login" />
              </Link>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <PrivacyPolicy color="black" tabindex={18} />
              <p
                style={{
                  color: "black",
                  fontWeight: "500",
                  marginRight: "10px",
                  fontSize: "13px",
                }}
              >
                |
              </p>
              <TermsAndPrivacy color="black" tabindex={19} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(LogOut);
