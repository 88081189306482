import React, { PureComponent } from "react";
import Button from "react-bootstrap/Button";

import "../../css/style.scss";

class AppButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Button
        className="appButton"
        style={{
          marginTop: "10px",
          color: "#1A4EA2",
          backgroundColor: "#FFE160",
          border: "none",
          borderRadius: 25,
          width: "fit-content",
          fontSize: "20px",
          fontWeight: "700",
          fontFamily: "museo-sans",
          letterSpacing: "2.5px",
          marginBottom: "30px",
          paddingRight: "40px",
          paddingLeft: "40px",
        }}
        type="Submit"
        onClick={this.props.onClick}
        tabIndex={this.props.tabindex}
        aria-label={this.props.arialabel}
      >
        {this.props.text}
      </Button>
    );
  }
}

export default AppButton;
