/** @format */

// PROD

export const GLOBAL_WEB_DOMAIN = "https://amenitypool.com/";
export const GLOBAL_SF_DOMAIN = "https://ap-maintenance.my.site.com";
export const GLOBAL_ENVIRONMENT = "PROD";

// // SANDBOX
// export const GLOBAL_WEB_DOMAIN = "jazzy-tarsier-1f5614.netlify.app";
// export const GLOBAL_SF_DOMAIN =
//   "https://ap-maintenance--pmanpart.sandbox.my.site.com";
// export const GLOBAL_ENVIRONMENT = "SANDBOX";

// SERVER
// LOCAL
// export const GLOBAL_DOMAIN = "http://localhost:5001";
// LIVE
export const GLOBAL_DOMAIN = "https://safe-lowlands-28027.herokuapp.com";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ATTEMPTED = "LOGIN_ATTEMPTED";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LI_EMAIL_CHANGED = "LI_EMAIL_CHANGED";
export const LI_PASSWORD_CHANGED = "LI_PASSWORD_CHANGED";
export const LI_TOKEN_CHANGED = "LI_TOKEN_CHANGED";
export const LI_CLEARALL = "LI_CLEARALL";
export const LI_ERROR = "LI_ERROR";

export const AS_ATTEMPTED = "AS_ATTEMPTED";
export const AS_SUCCESS = "AS_SUCCESS";
export const AS_FAIL = "AS_FAIL";
export const AS_EMAIL_CHANGED = "AS_EMAIL_CHANGED";
export const AS_PASSWORD1_CHANGED = "AS_PASSWORD1_CHANGED";
export const AS_PASSWORD2_CHANGED = "AS_PASSWORD2_CHANGED";
export const AS_NAME_CHANGED = "AS_NAME_CHANGED";
export const AS_CLEARALL = "AS_CLEARALL";
export const AS_ERROR = "AS_ERROR";
