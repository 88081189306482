import React, { PureComponent } from "react";

import "../../css/style.scss";

class AppHeaderText extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <h1 className="app-Header-Text" style={{ color: "#1A4EA2" }}>
        {this.props.text}
      </h1>
    );
  }
}

export default AppHeaderText;
