import React, { PureComponent } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AppNavbarWave from "../CustomComponents/AppNavbarWave";
// import TermsAndPrivacy from "../CustomComponents/TermsAndPrivacy";
import { GLOBAL_SF_DOMAIN, GLOBAL_WEB_DOMAIN } from "../../actions/types";
import PrivacyPolicy from "../CustomComponents/PrivacyPolicy";
class TermsAndConditions extends PureComponent {
  componentDidMount = async () => {
    let accountID = localStorage.getItem("accountId");
    let contactID = localStorage.getItem("contactId");
    if (!accountID && !contactID) {
      window.location.href = `${GLOBAL_SF_DOMAIN}/services/oauth2/authorize?client_id=3MVG9hq7jmfCuKfcfEvXMNxD433CfsCPKAYx8ArK6TiGKw1aiwZ6qkalCe7mrAraIjbIxajp2kecEnIMR1U40&redirect_uri=https://${GLOBAL_WEB_DOMAIN}/login&scope=refresh_token%20api%20web&response_type=code&prompt=login`;
    }
  };
  render() {
    return (
      <>
        <AppNavbarWave />
        <div className="termsAndConditionsContainer">
          <h1
            style={{
              fontSize: "42px",
              color: "#1A4EA2",
              fontFamily: "Oswald",
              fontWeight: "300",
            }}
          >
            Terms of Use
          </h1>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "300",
              fontStyle: "italic",
              margin: "40px 0px",
            }}
          >
            This Terms of Use (this “Policy”) governs your use of all websites
            administered or operated by Amenity Connect, LLC and its parent
            company, Amenity Connect, LLC (collectively, “Company”, “We”, “Us”
            or “Our”). If you do not agree with all of the terms of this Policy,
            you should not use, browse or otherwise access any of Our websites
            (collectively, “Company’s Websites”, and each, a “Website”). By
            browsing or using any of the Company’s Websites, you signify your
            agreement to this Policy.
          </p>
          {/* <h2
            style={{
              fontSize: "24px",
              color: "#1A4EA2",
              fontFamily: "museo-sans",
              fontWeight: "500",
            }}
          >
            TERMS OF USE
          </h2> */}
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "300",
              margin: "40px 0px",
            }}
          >
            All content contained on the Company’s Websites (collectively,
            “Content”), such as text, graphics, logos, icons, images, audio and
            video clips, digital downloads, data compilations, and software, is
            Our property or the property of Our licensors, and the compilation
            of the Content on the Website is Our exclusive property, protected
            by United States and international copyright laws, treaties and
            conventions.
          </p>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "300",
              margin: "40px 0px",
            }}
          >
            We grant you a personal, limited, non-exclusive and revocable
            license to access and make personal use of the Content in
            conjunction with your use of the Company’s Websites. No other uses
            of the Content are authorized or permissible without Our express
            written consent. You represent, warrant and agree that: you own or
            otherwise control all of the rights to all data and information that
            you post or send to us; that all such information is accurate; use
            of such information does not violate the terms of this Policy or the
            rights of any third party and will not cause injury to anyone; and,
            you will indemnify Us and Our affiliates and designees from and
            against all claims arising out of, resulting from or relating to any
            such information. We have the right (but no obligation) to monitor,
            edit or remove any activity or content involving you. We have no
            responsibility, and assume no liability, for any information or data
            posted or sent by you or by anyone else.
          </p>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "300",
              margin: "40px 0px",
            }}
          >
            The content is provided “as is” and without warranty of any kind,
            expressed or implied. To the fullest extent permitted by applicable
            law, we disclaim any and all warranties, expressed or implied,
            including, but not limited to, implied warranties of
            merchantability, fitness for a particular purpose and
            non-infringement. We do not warrant that the functions or features
            contained in any Website or the Content will be free of viruses or
            other harmful components. We make no representations or warranties
            regarding the use, or the results of use, of any Content, product or
            service displayed on, offered, made available through, or otherwise
            related in any way to any Website.
          </p>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "300",
              margin: "40px 0px",
            }}
          >
            A Website may offer users the ability to utilize the services of
            third party providers, such as credit card processing services that
            are necessary to complete association-related dues or payments
            through the Website. All issues involving transactions between a
            third party service provider and you must be handled with the
            applicable provider. The Company may try to facilitate the
            resolution of any such issues, however, you understand and agree
            that the Company has no control over, or liability for, the acts or
            omissions of third party providers. You hereby irrevocably and
            unconditionally waive any and all claims against us with respect to
            information, Content and materials contained on or accessible
            through the Company’s Websites and any third party sites and
            services. We strongly encourage you to make whatever investigation
            you feel necessary or appropriate before proceeding with any online
            or offline transaction.
          </p>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "300",
              margin: "40px 0px",
            }}
          >
            UNDER NO CIRCUMSTANCES (INCLUDING NEGLIGENCE) SHALL WE BE LIABLE TO
            YOU OR ANYONE ELSE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR
            CONSEQUENTIAL DAMAGES (INCLUDING LOST PROFITS), PERSONAL INJURY
            (INCLUDING DEATH) OR PROPERTY DAMAGE OF ANY KIND OR NATURE
            WHATSOEVER THAT ARISE OUT OF OR RESULT FROM: (A) THE USE OF, OR ANY
            INABILITY TO USE, THE COMPANY’S WEBSITES OR ANY CONTENT OR FUNCTIONS
            THEREOF, OR (B) ANY PRODUCTS OR INFORMATION OBTAINED ON OR THROUGH
            THE COMPANY’S WEBSITES, EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES
            FROM: PERSONAL INJURY; DOWNLOADING ANY MATERIAL CONTAINED ON OR
            ACCESSED THROUGH THE COMPANY’S WEBSITES; ANY UNAUTHORIZED ACCESS TO
            ANY INFORMATION ON THE COMPANY’S WEBSITES AND/OR SUBMITTED BY YOU TO
            OR THROUGH THE COMPANY’S WEBSITES; AND/OR LOSS OF PROFITS, USE, DATA
            OR OTHER INTANGIBLE LOSSES. IN NO EVENT SHALL OUR TOTAL LIABILITY TO
            YOU FOR ALL LOSS, COST, DAMAGE, LIABILITY OR EXPENSE (INCLUDING
            ATTORNEYS FEES AND COSTS) THAT YOU MAY SUFFER OR INCUR, UNDER ANY
            THEORY OF LIABILITY, IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED
            TO, NEGLIGENCE) OR OTHERWISE, EXCEED THE LESSER OF THE AMOUNT PAID
            BY YOU, IF ANY, FOR THE RIGHT TO ACCESS OR PARTICIPATE IN ANY
            ACTIVITY RELATED TO THE COMPANY’S WEBSITES OR $100.00.
          </p>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "300",
              margin: "40px 0px",
            }}
          >
            The interpretation and enforcement of this Policy shall be governed
            under the laws of the State of Florida. The sole and exclusive venue
            for any and all issues, claims or causes of action arising from or
            related to this Agreement shall be Broward County, Florida.
          </p>
          <h2
            style={{
              fontSize: "24px",
              color: "#1A4EA2",
              fontFamily: "museo-sans",
              fontWeight: "500",
            }}
          >
            MOBILE TERMS AND CONDITIONS
          </h2>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "300",
              margin: "40px 0px",
            }}
          >
            Effective Date: 4/1/2023
          </p>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "700",
              margin: "40px 0px",
            }}
          >
            Amenity Pool Services Alerts
          </p>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "300",
              margin: "40px 0px",
            }}
          >
            We may use SMS to contact you for timely information from Amenity
            Pool Services. Please opt in to recieve messages from Amenity Pool
            Services like payment reminders, product and service specials and
            maintenance recommendations by{" "}
            <Link
              to={`/AccountDetails/${"Notifications"}`}
              style={{
                fontFamily: "museo-sans",
                fontSize: "16px",
                fontWeight: "300",
                color: "#1A4EA2",
              }}
            >
              filling in our contact form and checking the SMS ‘opt in’ box
            </Link>
            .{" "}
            <span
              style={{
                fontFamily: "museo-sans",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              Msg frq varies, message and data rates may apply.
            </span>
          </p>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "300",
              margin: "40px 0px",
            }}
          >
            By opting in to this service, you consent to receive mobile text
            alerts using an automatic telephone dialing system. Consent to
            receive marketing text messages is not required as a condition of
            purchasing any goods or services.
          </p>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "300",
              margin: "40px 0px",
            }}
          >
            By signing up, you are confirming you are over the age of 13.
          </p>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "700",
              margin: "40px 0px",
            }}
          >
            STOP Information
          </p>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "300",
              margin: "40px 0px",
            }}
          >
            Text{" "}
            <span
              style={{
                fontFamily: "museo-sans",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              STOP
            </span>{" "}
            to [short code] to stop receiving Amenity Pool Services Alerts from
            us (you will receive a confirmation text).Msg frq varies, message
            and data rates may apply.
          </p>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "700",
              margin: "40px 0px",
            }}
          >
            HELP Information
          </p>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "300",
              margin: "40px 0px",
            }}
          >
            For additional information, text{" "}
            <span
              style={{
                fontFamily: "museo-sans",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              HELP
            </span>{" "}
            to{" "}
            <span
              style={{
                fontFamily: "museo-sans",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              59189
            </span>{" "}
            or contact{" "}
            <span
              style={{
                fontFamily: "museo-sans",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              1-800-990-7440.
            </span>
          </p>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "700",
              margin: "40px 0px",
            }}
          >
            Supported carriers are:
          </p>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "300",
              margin: "40px 0px",
            }}
          >
            AT&T, Sprint, T-Mobile®, Verizon Wireless, Boost, Cricket, MetroPCS,
            U.S. Cellular, Virgin Mobile, Google Voice, ACS Wireless, Advantage
            Cellular (DTC Wireless), Appalachian Wireless, Atlantic Tele-Network
            International (ATN), Bandwidth, Bluegrass Cellular, Buffalo
            Wireless, CableVision, Carolina West Wireless, Cellcom, Copper
            Valley, C-Spire Wireless (formerly Cellsouth), Cellular One of East
            Central Illinois, Chariton Valley Cellular, Cross (dba Sprocket),
            Duet IP, Element Mobile, EpicTouch, GCI Communications, Golden
            State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri Cellular), i
            Wireless (IOWA Wireless), Illinois Valley Cellular, Immix (Keystone
            Wireless / PC Management), Inland Cellular, Mobi PCS (Coral Wireless
            LLC), Mosaic, MTA Communications, MTPCS / Cellular One (Cellone
            Nation), Nex-Tech Wireless, Panhandle Telecommunications, Peoples
            Wireless, Pine Belt Wireless, Pine Cellular, Pioneer, Plateau, Revol
            Wireless, RINA, SI Wireless/Mobile Nation, SouthernLinc, SRT
            Wireless, Thumb Cellular, Union Wireless, United, Viaero Wireless,
            West Central Wireless, Leaco, Nemont/Sagebrush. T-Mobile is not
            liable for delayed or undelivered messages.
          </p>
          <p
            style={{
              fontFamily: "museo-sans",
              fontSize: "16px",
              fontWeight: "300",
              margin: "40px 0px",
            }}
          >
            We take your privacy seriously.{" "}
            <Link
              to="/PrivacyPolicy"
              style={{
                fontFamily: "museo-sans",
                fontSize: "16px",
                fontWeight: "300",
                color: "#1A4EA2",
              }}
            >
              Please review our privacy policy
            </Link>
            .
          </p>

          <Link
            to={"/Dashboard"}
            className="returnHomeLink"
            style={{
              color: "#D54100",
              marginBottom: 30,
            }}
            tabIndex={17}
            aria-label="Return to dashboard"
          >
            RETURN TO HOME PAGE
          </Link>
          <div style={{ paddingBottom: "20px" }}>
            {/* <TermsAndPrivacy color="#727272" tabindex={18} /> */}
            <PrivacyPolicy color="#727272" tabindex={18} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(TermsAndConditions);
