/** @format */

import React, { PureComponent } from "react";
import "../../css/style.scss";
import Nav from "react-bootstrap/Nav";

class InvoicesDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: {},
      showChangePassword: false,
      changePasswordSuccess: false,
    };
  }
  handleMouseLeaveFunc = () => {
    this.props.handleMouseLeave();
  };

  render() {
    return (
      <div onMouseLeave={() => this.handleMouseLeaveFunc()}>
        <Nav className="invoicesDropdownContainer">
          <Nav.Item
            style={{ borderBottomWidth: "1px", borderBottomColor: "white" }}
          >
            <Nav.Link
              className="navDropdownItem"
              href="/Payments"
              eventKey="PayMyBill"
              tabIndex={8}
              aria-label="Navigate to payments screen"
            >
              VIEW INVOICES
            </Nav.Link>
          </Nav.Item>
          <span className="accountDropdownSpacer" />
          <Nav.Item>
            <Nav.Link
              className="navDropdownItem"
              eventKey="View Previous Payments"
              href="/MyInvoices"
              tabIndex={9}
              aria-label="Navigate to my invoices"
            >
              VIEW PREVIOUS PAYMENTS
            </Nav.Link>
          </Nav.Item>
          <span className="accountDropdownSpacer" />
          <Nav.Item>
            <Nav.Link
              className="navDropdownItem"
              eventKey="AddPaymentMethod"
              href="/AddNewPaymentMethod"
              tabIndex={10}
              aria-label="Navigate to add new payment screen"
            >
              ADD PAYMENT METHOD
            </Nav.Link>
          </Nav.Item>
          <span className="accountDropdownSpacer" />
          <Nav.Item>
            <Nav.Link
              className="navDropdownItem"
              href={`/AccountDetails/${"AutoPay"}`}
              eventKey="SetUpAutoPay"
              tabIndex={11}
              aria-label="Navigate to set autopay"
              //   onClick={() => {
              //     localStorage.removeItem("contactId");
              //     localStorage.removeItem("accountId");
              //     localStorage.removeItem("token");
              //     onASClearFields();
              //   }}
            >
              SET UP AUTO PAY
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    );
  }
}

export default InvoicesDropdown;
