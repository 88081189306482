import React, { PureComponent } from "react";

import "../../css/style.scss";

class ServicesDayDial extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="servicesDialContainer">
        <h1
          className="servicesDialDay"
          style={{
            color: this.props.day === "Sunday" ? "white" : "#727272",
            padding: this.props.day === "Sunday" && "5px",
            borderRadius: this.props.day === "Sunday" && "50%",
            backgroundColor: this.props.day === "Sunday" && "#1A4EA2",
          }}
        >
          S
        </h1>
        <h1
          className="servicesDialDay"
          style={{
            color: this.props.day === "Monday" ? "white" : "#727272",
            padding: this.props.day === "Monday" && "5px",
            borderRadius: this.props.day === "Monday" && "50%",
            backgroundColor: this.props.day === "Monday" && "#1A4EA2",
          }}
        >
          M
        </h1>
        <h1
          className="servicesDialDay"
          style={{
            color: this.props.day === "Tuesday" ? "white" : "#727272",
            padding: this.props.day === "Tuesday" && "5px",
            borderRadius: this.props.day === "Tuesday" && "50%",
            backgroundColor: this.props.day === "Tuesday" && "#1A4EA2",
          }}
        >
          T
        </h1>
        <h1
          className="servicesDialDay"
          style={{
            color: this.props.day === "Wednesday" ? "white" : "#727272",
            padding: this.props.day === "Wednesday" && "5px",
            borderRadius: this.props.day === "Wednesday" && "50%",
            backgroundColor: this.props.day === "Wednesday" && "#1A4EA2",
          }}
        >
          W
        </h1>
        <h1
          className="servicesDialDay"
          style={{
            color: this.props.day === "Thursday" ? "white" : "#727272",
            padding: this.props.day === "Thursday" && "5px",
            borderRadius: this.props.day === "Thursday" && "50%",
            backgroundColor: this.props.day === "Thursday" && "#1A4EA2",
          }}
        >
          T
        </h1>
        <h1
          className="servicesDialDay"
          style={{
            color: this.props.day === "Friday" ? "white" : "#727272",
            padding: this.props.day === "Friday" && "5px",
            borderRadius: this.props.day === "Friday" && "50%",
            backgroundColor: this.props.day === "Friday" && "#1A4EA2",
          }}
        >
          F
        </h1>
        <h1
          className="servicesDialDay"
          style={{
            color: this.props.day === "Saturday" ? "white" : "#727272",
            padding: this.props.day === "Saturday" && "5px",
            borderRadius: this.props.day === "Saturday" && "50%",
            backgroundColor: this.props.day === "Saturday" && "#1A4EA2",
          }}
        >
          S
        </h1>
      </div>
    );
  }
}

export default ServicesDayDial;
