import React, { PureComponent } from "react";
import "../../css/style.scss";

import { GoTriangleRight, GoTriangleDown } from "react-icons/go";
import AppSubHeaderText from "./AppSubHeader";

class AppPastInvoicesAccordian extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showMoreThanFive: false,
      selectedDescription: "",
    };
  }
  setSelectedService = (id) => {
    this.props.setSelectedService(id);
  };
  setSelectedRepair = (id) => {
    this.props.setSelectedRepair(id);
  };
  setSelectedDescription = (id) => {
    this.props.setSelectedDescription(id);
  };
  renderInvoiceItems = (item, i) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            // marginLeft: "45px",
            marginRight: "20px",
            marginVertical: "0px",
            fontFamily: "museo-sans",
            fontWeight: "300",
            color: "#727272",
            display: "flex",
            alignItems: "center",
            marginBottom: "0px",
          }}
        >
          {/* {console.log("ITEM", item)} */}
          {item?.Product_Name__c}
        </p>
        <div>
          <p
            className="prevInvoicePriceText"
            style={{
              color: "#727272",
            }}
          >
            {item?.Net_Amount__c?.toFixed(2)}
          </p>
        </div>
      </div>
    );
  };

  renderItem = (item, i) => {
    let headerText = item?.pending
      ? `Date Paid: Pending`
      : `Date Paid: ${item?.Posting_Date__c}`;
    let invoiceItems = item?.Invoice_Line_Items__r?.records;
    return (
      <div key={item.Id}>
        <button
          aria-label="Show item description"
          style={{
            border: "none",
            backgroundColor: "white",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // height: this.state.selectedDescription === item.Id ? "500px" : "60px",
            minHeight: "60px",
            borderTop: "1px solid #E9E9E9",
          }}
          tabIndex={17}
          onClick={() => {
            // console.log("Hit", item);
            if (this.state.selectedDescription === item.Id) {
              this.setState({ selectedDescription: "" });
            } else {
              this.setState({ selectedDescription: item.Id });
            }
          }}
        >
          <p
            className="appAccordianName"
            style={{
              color:
                this.state.selectedDescription === item.Id
                  ? "#1A4EA2"
                  : "#727272",
            }}
          >
            {item?.pending
              ? `${item?.Subject__c} - PENDING`
              : `${item?.Subject__c}`}
          </p>
          {this.state.selectedDescription === item.Id ? (
            <GoTriangleDown size={28} color="#1A4EA2" />
          ) : (
            <GoTriangleRight size={30} color="#727272" />
          )}
        </button>
        {this.state.selectedDescription === item.Id && (
          <div style={{ paddingLeft: "10px" }}>
            <AppSubHeaderText
              text={
                headerText && !headerText.includes("null")
                  ? headerText
                  : "Date Paid: No Posting Date Set"
              }
            />
            {/* {console.log("this", item?.Invoice_Line_Items__r?.records)} */}
            {this.state.selectedDescription === item.Id && (
              <div>
                {invoiceItems.map((item, i) => {
                  return this.renderInvoiceItems(item);
                })}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <p
                    // className="prevInvoicePriceText"
                    style={{
                      fontWeight: "bold",
                      // color: "#939393",
                      marginTop: "10px",
                      fontFamily: "Oswald",
                      fontSize: "24px",
                    }}
                  >
                    Total: ${item.Total_Amount__c}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };
  render() {
    return (
      <>
        {this.props.invoices.map((item, i) => {
          if (!this.props.showMoreThanFive && i < 5) {
            return this.renderItem(item, i);
          } else if (this.props.showMoreThanFive) {
            return this.renderItem(item, i);
          } else {
            return "";
          }
        })}
      </>
    );
  }
}

export default AppPastInvoicesAccordian;
