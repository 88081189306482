import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";

import amenityServicesLogo from "../../assets/logos/amenityServicesLogo.png";
import backgroundLarge from "../../assets/imgs/waveHorizontal.png";

import { Navbar, Image } from "react-bootstrap";
import MobileNavbar from "./MobileNavbar";
import { GLOBAL_DOMAIN } from "../../actions/types";
import { onASClearFields } from "../../actions";
import axios from "axios";

class LogoutNavbar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      account: false,
      invoices: false,
      showMobileNavbar: false,
    };
  }
  onExitPress = () => {
    this.setState({ showMobileNavbar: false });
  };
  async logOut() {
    const token = localStorage.getItem("token");
    localStorage.removeItem("token");
    try {
      await axios.get(`${GLOBAL_DOMAIN}/api/auth/logout`, {
        params: {
          token,
          baseDomain: window.location.href,
        },
      });
      // console.log("logoutResponse", logoutResponse);
      localStorage.removeItem("contactId");
      localStorage.removeItem("accountId");
      localStorage.removeItem("code");
      localStorage.removeItem("dashboardMessageSeen");
      onASClearFields();
    } catch (err) {
      console.log(err);
    }
  }
  render() {
    return (
      <>
        {/* Desktop Nav */}
        <Navbar
          expand="lg"
          className="mainNavbar"
          style={{
            top: "-20px",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundImage: `url(${backgroundLarge})`,
            backgroundRepeat: "round",
            height: "225px",
          }}
        >
          <Navbar.Brand
            as={Link}
            to="/login"
            onClick={() => this.setState({ account: false })}
            style={{ margin: "0px 10%" }}
            aria-label="Return to login"
          >
            <Image
              alt="Poolman logo"
              style={{
                height: "75px",
                // marginLeft: "15%",
                // marginRight: "15%",
                marginBottom: "20px",
              }}
              src={amenityServicesLogo}
            ></Image>
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => this.setState({ account: false })}
            style={{ color: "#1A4EA2" }}
          />
        </Navbar>
        <MobileNavbar
          show={this.state.showMobileNavbar}
          onExitPress={this.onExitPress}
        />
        {/* Mobile Nav */}
        <div
          className="mobileNavbar"
          style={{
            top: "-20px",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundImage: `url(${backgroundLarge})`,
            backgroundRepeat: "round",
            height: "150px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0% 5%",
            }}
          >
            <Link to={"/Dashboard"} aria-label="Return to dashboard">
              <Image
                style={{
                  height: "50px",
                  marginTop: "25px",
                }}
                alt="Poolman logo"
                src={amenityServicesLogo}
              ></Image>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(LogoutNavbar);
